/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";

// Local Imports
import {
  Carousel,
  DetailModal,
  Divider,
  showToast,
  Skeleton,
  UploadImageButton,
} from "../../../../components/shared";
import {
  BagIcon,
  BirthdayIcon,
  EditIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
} from "../../../../assets/icons";
import { capitalize, formatDate } from "../../../../utils/utils";
import { assets } from "../../../../assets/images";
import { Experiences } from "./Experiences";
import { Education } from "./Education";
import { Certifications } from "./Certifications";
import { Recommendations } from "./Recommentdations";
import { Skills } from "./Skills";
import Licences from "./Licences";
import { Projects } from "./Projects";
import { getProfileDetailFunction } from "../../../../redux/reducers/userReducers/editProfileReducers/editProfileReducer";
import {
  getPortfolioImagesFunction,
  uploadPortfolioImagesFunction,
} from "../../../../redux/reducers/userReducers/PortfolioImagesReducer/PortfolioImagesReducer";

export const ProfileDetails = () => {
  const navigate = useNavigate();
  const { loading, profileData: profileDetails } = useSelector(
    (state) => state.profileDetails
  );

  const { id } = useParams();
  const dispatch = useDispatch();
  // Get User Details
  const getUserDetails = async () => {
    const data = await dispatch(profileDetails({ id: id }));

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }
  };
  const getProfileDetail = async () => {
    await dispatch(getProfileDetailFunction());
  };

  useLayoutEffect(() => {
    getProfileDetail();
  }, []);
  // State for managing modals
  const [modals, setModals] = useState({
    experience: { open: false, data: null },
    projects: { open: false, data: null },
    education: { open: false, data: null },
    certification: { open: false, data: null },
    license: { open: false, data: null },
  });
  // Open specific modal with data
  const handViewOpenModal = (type, data = null) => {
    setModals((prev) => ({
      ...prev,
      [type]: { open: true, data },
    }));
  };

  // Close all modals
  const handleNewCloseModal = () => {
    setModals({
      experience: { open: false, data: null },
      projects: { open: false, data: null },
      education: { open: false, data: null },
      certification: { open: false, data: null },
      license: { open: false, data: null },
    });
  };

  // profile images
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 * 1024) {
        setErrorMessage(`Image ${files[i].name} is larger than 1 MB.`);
        return;
      }
    }

    // if (files.length + images.length > 4) {
    //   setErrorMessage("You can only upload up to 4 images.");
    //   return;
    // }

    const formData = new FormData();
    files.forEach((file) => formData.append("images", file));

    // Dispatch upload action
    const data = await dispatch(uploadPortfolioImagesFunction(formData));

    // Error handling
    if (data?.meta?.requestStatus === "rejected") {
      setErrorMessage(data?.payload ? data?.payload : "Something went wrong");
      return false;
    }

    // Success: Fetch updated image listing
    if (data?.meta?.requestStatus === "fulfilled") {
      dispatch(getPortfolioImagesFunction());
    }

    setErrorMessage(""); // Clear error on success
  };

  return (
    <div className="pb-2">
      <div className="relative font-nunito mx-3 mt-3 border border-grey/30 rounded-2xl overflow-hidden">
        <div className="relative">
          <div className="z-10 relative pl-6 py-6 pb-[4.5rem]">
            <div className="vertical-center gap-3">
              <h3 className="text-xl">Profile</h3>
            </div>
          </div>
          <img
            src={assets?.coverbg}
            loading="lazy"
            alt="cover-image"
            className="w-full absolute top-0 left-0 z-0 h-48 object-cover rounded-t-xl"
          />
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-baseline justify-center sm:justify-between relative z-10 mb-5">
              <div className="vertical-center justify-center border-4 bg-white border-white rounded-full z-10">
                {loading ? (
                  <Skeleton className="size-32 rounded-full" />
                ) : (
                  <Avatar
                    src={
                      profileDetails?.profile_image?.startsWith("http")
                        ? profileDetails.profile_image
                        : undefined
                    }
                    className="!size-32"
                    color="secondary"
                  />
                )}
              </div>
              <div
                className="hidden sm:vertical-center gap-2 font-semibold cursor-pointer select-none hover:text-secondary transition"
                onClick={() => navigate("/profile")}
              >
                {loading ? (
                  <Skeleton className="w-28 h-6 rounded-full" />
                ) : (
                  <>
                    {profileDetails ? (
                      <>
                        <EditIcon />
                        <p>Edit Profile</p>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div className="flex flex-col items-center sm:items-start">
                {loading ? (
                  <>
                    <Skeleton className="w-40 h-6 rounded-full mb-3" />
                    <Skeleton className="w-36 h-4 rounded-full" />
                  </>
                ) : (
                  <h3 className="font-manrope font-bold text-2xl">
                    {capitalize(profileDetails?.first_name) +
                      " " +
                      capitalize(profileDetails?.last_name)}
                  </h3>
                )}
                {profileDetails?.mailing_address ? (
                  <div className="vertical-center gap-2 text-grey">
                    <LocationIcon width={16} height={16} />
                    <p className="font-normal text-base leading-7 text-grey">
                      {profileDetails?.mailing_address?.city?.name +
                        ", " +
                        profileDetails?.mailing_address?.state?.name +
                        ", " +
                        profileDetails?.mailing_address?.zipcode}
                    </p>
                  </div>
                ) : null}
              </div>
              <div
                className="sm:hidden center gap-2 font-semibold cursor-pointer hover:text-secondary transition"
                onClick={() => navigate("/profile")}
              >
                {loading ? (
                  <Skeleton className="w-28 h-6 rounded-full" />
                ) : (
                  <>
                    <EditIcon />
                    <p>Edit Profile</p>
                  </>
                )}
              </div>

              {loading ? (
                <Skeleton className="w-44 h-12 rounded-full" />
              ) : (
                <>
                  {profileDetails?.profession ? (
                    <div className="rounded-full py-3 px-4 bg-bggrey/80 hover:bg-primary/20 hover:text-secondary vertical-center transition">
                      <BagIcon />
                      <span className="px-2 font-medium text-base leading-7">
                        {profileDetails?.profession}
                      </span>
                    </div>
                  ) : null}
                </>
              )}
            </div>

            <div className="smd:grid grid-cols-12 mb-5 gap-1">
              <div className="flex flex-col gap-3 w-full col-start-1 col-span-6 max-smd:mt-10">
                {loading ? (
                  <>
                    <Skeleton className="w-60 h-3 rounded-full" />
                    <Skeleton className="w-44 h-3 rounded-full" />
                    <Skeleton className="w-60 h-3 rounded-full" />
                    <Skeleton className="w-28 h-3 rounded-full" />
                  </>
                ) : (
                  <>
                    {/* Email */}
                    <div className="flex items-center gap-2 text-darkgrey mt-4">
                      <MailIcon width={18} height={18} />
                      <p className="text-sm leading-4">
                        {profileDetails?.email}
                      </p>
                    </div>

                    {/* Phone Number */}
                    <div className="flex items-center gap-2 text-darkgrey">
                      <PhoneIcon width={19} height={19} />
                      <p className="text-sm leading-4">
                        {profileDetails?.phone_no}
                      </p>
                    </div>

                    {/* Operated Address */}
                    {profileDetails?.operated_address && (
                      <div className="flex items-center gap-2 text-darkgrey">
                        <LocationIcon width={16} height={16} />
                        <p className="text-sm leading-4">
                          <span className="font-bold">Operates from: </span>
                          {`${profileDetails?.operated_address?.city?.name}, 
                          ${profileDetails?.operated_address?.state?.name}, 
                          ${profileDetails?.operated_address?.zipcode}`}
                        </p>
                      </div>
                    )}

                    {/* Birthday */}
                    {profileDetails?.birthday && (
                      <div className="flex items-center gap-2 text-darkgrey">
                        <BirthdayIcon width={19} height={19} />
                        <p className="text-sm leading-4">
                          {formatDate(profileDetails?.birthday)}
                        </p>
                      </div>
                    )}

                    {/* Description Section */}
                    {profileDetails?.user_description && (
                      <div className="w-[290px]">
                        <Divider />
                        <p className="text-sm leading-5 text-darkgrey mt-3 ">
                          {profileDetails?.user_description}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="w-full max-smd:mt-10 col-span-7 smd:col-span-5 md:col-span-4 lg:col-span-3 col-start-7 smd:col-start-8 md:col-start-9 lg:col-start-10">
                <div>
                  <p className="text-md font-semibold">Role</p>
                  <Divider />
                  <div className="mt-3 flex flex-wrap items-center gap-2">
                    {loading ? (
                      <Skeleton className="flex rounded-full w-24 h-6" />
                    ) : (
                      <>
                        {profileDetails?.user_roles &&
                          profileDetails?.user_roles.map((role, index) => (
                            <p
                              key={index}
                              className="text-sm bg-gold/30 rounded-full w-fit px-3 py-1"
                            >
                              {role}
                            </p>
                          ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-md font-semibold">Interests</p>
                  <Divider />
                  <div className="mt-3 flex flex-wrap items-center gap-2">
                    {loading ? (
                      <>
                        {Array.from({ length: 3 }, (_, index) => (
                          <Skeleton
                            key={index}
                            className="flex rounded-full w-24 h-6"
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {profileDetails?.user_interests &&
                          profileDetails?.user_interests?.map(
                            (interest, index) => (
                              <p
                                key={index}
                                className="text-sm bg-gold/30 rounded-full w-fit px-3 py-1"
                              >
                                {interest}
                              </p>
                            )
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            {profileDetails?.experiences?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Experiences</p>
                <Experiences
                  loading={loading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />
            <div className="my-6 ">
              <div
                className={`relative flex justify-between items-center ${
                  errorMessage ? "mb-2" : ""
                }`}
              >
                <p className="text-lg font-semibold">Portfolio</p>

                <UploadImageButton
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  maxImages={4}
                  handleImageUpload={handleImageUpload}
                />
              </div>

              <Carousel
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <Divider />

            {profileDetails?.project_experiences?.length > 0 ? (
              <div className="my-6 cursor-pointer">
                <p className="text-lg font-semibold">Projects</p>
                <Projects
                  loading={loading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {profileDetails?.education?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Education</p>
                <Education
                  loading={loading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {profileDetails?.certifications?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Certifications</p>
                <Certifications
                  loading={loading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {profileDetails?.licenses?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Licences</p>
                <Licences
                  loading={loading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {profileDetails?.recommendations?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Recommendations</p>
                <Recommendations
                  loading={loading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                />
              </div>
            ) : null}

            <Divider />

            {profileDetails?.skills?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Skills</p>
                <Skills loading={loading} skills={profileDetails?.skills} />
              </div>
            ) : null}
            {/* <ProfileCarousel /> */}
          </div>
        </div>
      </div>
      {Object.keys(modals).map((key) => (
        <DetailModal
          key={key}
          title={key.charAt(0).toUpperCase() + key.slice(1)}
          data={modals[key].data}
          open={modals[key].open}
          onClose={handleNewCloseModal}
        />
      ))}
    </div>
  );
};
