/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  SelectDropdown,
  showToast,
  TextInput,
  CustomModal,
  AddMore,
  Spinner,
  Skeleton,
  CustomCheckbox,
  CustomPopover,
  IconNavigation,
} from "../../../../components/shared";
import {
  DeleteIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  LongArrow,
} from "../../../../assets/icons";
import {
  deleteExperienceFunction,
  getExperienceFunction,
  updateExperienceFunction,
} from "../../../../redux/reducers/userReducers/experienceReducer/experienceReducer";
import { jobTypeData } from "../../../../utils/mockupData";
import { isEmpty } from "../../../../utils/validations";
import { capitalize, formatDate } from "../../../../utils/utils";
import {
  deleteProjectFunction,
  getProjectFunction,
  updateProjectFunction,
} from "../../../../redux/reducers/userReducers/projectReducers/projectRecuer";
import {
  citiesFunction,
  statesFunction,
} from "../../../../redux/reducers/authReducers/statesReducer";
import { ProfileDeleteModal } from "../DeleteModal";

// Initial Experience Data
const initialExperienceData = {
  jobType: "",
  jobTitle: "",
  company: "",
  startDate: "",
  endDate: "",
  isOngoing: false,
  proofUpload: "",
};

// Initial Project Data
const initialProjectData = {
  projectName: "",
  state: null,
  city: null,
  isOngoing: false,
  endDate: "",
  startDate: "",
  proofUpload: "",
};
export const Experience = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cityLoading } = useSelector((state) => state?.states);
  const { experiences: experienceListing, loading: experienceLoading } =
  useSelector((state) => state?.experience);
  const { projects: projectsListing, loading: projectsLoading } = useSelector(
    (state) => state?.projects
  );
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [experience, setExperience] = useState(initialExperienceData);
  const [project, setProject] = useState(initialProjectData);
  console.log(project?.state)
  const [statesListing, setStatesListing] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [openPopover, setOpenPopover] = useState({});
  const [openProjectPopover, setopenProjectPopover] = useState({});
  const [dateError, setDateError] = useState({
    experience: { startDate: "", endDate: "" },
    project: { startDate: "", endDate: "" },
  });

  // Get Experience
  const getExperience = async () => {
    await dispatch(getExperienceFunction());
  };

  // Get Projects
  const getProjects = async () => {
    await dispatch(getProjectFunction());
  };

  // Get States
  const getStates = async () => {
    const statesList = await dispatch(statesFunction());
    setStatesListing(statesList?.payload);
  };

  useLayoutEffect(() => {
    getExperience();
    getProjects();
    getStates();
  }, []);

  // Get Cities Listing
  const getCities = async () => {
    const citiesList = await dispatch(
      citiesFunction({ id: project?.state })
    );
    setCitiesList(citiesList?.payload);
  };

  useLayoutEffect(() => {
    if (!project?.state) return;
    getCities();
  }, [project?.state]);

  const today = new Date().toISOString().split("T")[0];
  const handleExperienceChange = (event) => {
    const { name, value } = event.target;

    setExperience((prev) => ({ ...prev, [name]: value }));

    if (name.includes("Date")) {
      let errorMessage = "";
      let updatedExperience = { ...experience, [name]: value };

      if (name === "startDate" && value > today) {
        errorMessage = "Start date cannot be in the future.";
        updatedExperience.startDate = ""; // Clear invalid start date
      }

      setExperience(updatedExperience);
      setDateError((prev) => ({
        ...prev,
        experience: { ...prev.experience, [name]: errorMessage },
      }));
    }
  };

  const handleProjectChange = (event) => {
    const { name, value } = event.target;
    const today = new Date().toISOString().split("T")[0];

    setProject((prev) => ({ ...prev, [name]: value }));

    if (name.includes("Date")) {
      let errorMessage = "";
      let updatedProject = { ...project, [name]: value };

      if (name === "startDate" && value > today) {
        errorMessage = "Start date cannot be in the future.";
        updatedProject.startDate = ""; // Clear invalid start date
      }

      setProject(updatedProject);
      setDateError((prev) => ({
        ...prev,
        project: { ...prev.project, [name]: errorMessage },
      }));
    }
  };

  const addExperience = async () => {
    const today = new Date().toISOString().split("T")[0];
    let errorMessage = "";

    // Validate endDate
    if (experience.endDate) {
      if (experience.endDate > today) {
        errorMessage = "End date cannot be in the future.";
        experience.endDate = "";
      } else if (experience.endDate < experience.startDate) {
        errorMessage = "End date cannot be before start date.";
        experience.endDate = "";
      }
    }

    // If there's an error, set the error state and stop the submission
    if (errorMessage) {
      setDateError((prev) => ({
        ...prev,
        experience: { ...prev.experience, endDate: errorMessage },
      }));
      return;
    }

    if (!experience.id && isExperienceExists(experience)) {
      showToast("Experience already exists!", "error", {
        position: "top-center",
      });
      return;
    }
    const formData = new FormData();
    formData.append("job_title", experience?.jobTitle);
    formData.append(
      "type",
      jobTypeData[experience?.jobType[0] - 1]?.name?.toUpperCase()
    );
    formData.append("employer", experience?.company);
    formData.append("start_date", experience?.startDate);
    formData.append("is_verified", true);
    formData.append("is_ongoing", experience?.isOngoing);

    if (experience?.endDate) {
      formData.append("end_date", experience.endDate);
    }
    if (experience?.proofUpload) {
      if (
        typeof experience.proofUpload === "object" &&
        experience.proofUpload instanceof File
      ) {
        formData.append("file_upload", experience.proofUpload);
      }
    }
    const id = experience?.id;
    const data = await dispatch(updateExperienceFunction({ formData, id }));

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenExperienceModal(false);
      showToast(
        experience?.id
          ? "Experience Updated Successfully"
          : "Experience Added Successfully",
        "success",
        {
          position: "top-center",
        }
      );
      getExperience();
      setExperience(initialExperienceData);
    } else if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join()).join("\n"),
        "error",
        { position: "top-center" }
      );
    }
  };

  const addProject = async () => {
    const today = new Date().toISOString().split("T")[0];
    let errorMessage = "";

    // Validate endDate
    if (project.endDate) {
      if (project.endDate > today) {
        errorMessage = "End date cannot be in the future.";
        project.endDate = "";
      } else if (project.endDate < project.startDate) {
        errorMessage = "End date cannot be before start date.";
        project.endDate = "";
      }
    }

    // If there's an error, set the error state and stop the submission
    if (errorMessage) {
      setDateError((prev) => ({
        ...prev,
        project: { ...prev.project, endDate: errorMessage },
      }));
      return;
    }
    if (!project.id && isProjectExists(project)) {
      showToast("Project already exists!", "error", { position: "top-center" });
      return;
    }
    const formData = new FormData();
    formData.append("project_name", project?.projectName);
    formData.append("location.city", project?.city);
    formData.append("start_date", project?.startDate);
    formData.append("is_verified", true);
    formData.append("is_ongoing", project?.isOngoing);

    if (!project?.isOngoing && project?.endDate) {
      formData.append("end_date", project?.endDate);
    }
    if (project?.proofUpload) {
      formData.append("file_upload", project?.proofUpload);
    }

    const id = project?.id;
    const data = await dispatch(updateProjectFunction({ formData, id }));

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenProjectModal(false);
      showToast(
        project?.id
          ? "Project Updated Successfully"
          : "Project Added Successfully",
        "success",
        {
          position: "top-center",
        }
      );
      getProjects();
      setProject(initialProjectData);
    } else if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join()).join("\n"),
        "error",
        { position: "top-center" }
      );
    }
  };

  useEffect(() => {
    if (dateError) {
      const timeout = setTimeout(() => {
        setDateError({
          experience: { startDate: "", endDate: "" },
          project: { startDate: "", endDate: "" },
        });
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [dateError]);
  // Close Experience Modal
  const handleCloseExperienceModal = () => {
    setOpenExperienceModal(false);
    setExperience(initialExperienceData);
  };

  // Close Project Modal
  const handleCloseProjectModal = () => {
    setOpenProjectModal(false);
    setProject(initialProjectData);
  };

  // Delete Experience
  const deleteExperience = async (id) => {
    const data = await dispatch(
      deleteExperienceFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Experience Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getExperience();
    }
  };

  // Delete Project
  const deleteProject = async (id) => {
    const data = await dispatch(
      deleteProjectFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Project Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getProjects();
    }
  };

  // Handle checkbox
  const handleCheckbox = (checked) => {
    if (checked === true) {
      setProject((prev) => ({
        ...prev,
        endDate: "",
      }));
    }
    setProject((prev) => ({
      ...prev,
      isOngoing: checked,
    }));
  };

  const handleExperienceCheckbox = (checked) => {
    if (checked === true) {
      setExperience((prev) => ({
        ...prev,
        endDate: "",
      }));
    }
    setExperience((prev) => ({
      ...prev,
      isOngoing: checked,
    }));
  };

  // Handle file change for proofUpload
  const handleExperienceFileChange = (event) => {
    const file = event.target.files[0];
    setExperience((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  // Handle file change for proofUpload
  const handleProjectFileChange = (event) => {
    const file = event.target.files[0];
    setProject((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  const handlePopoverToggle = (index) => {
    setOpenPopover((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const handleProjectPopoverToggle = (index) => {
    setopenProjectPopover((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const getJobTypeIdByName = (name) => {
    const jobType = jobTypeData.find(
      (job) => job.name.toLowerCase() === name.toLowerCase()
    );
    return jobType ? String(jobType.id) : null;
  };

  const isExperienceExists = (newExperience) => {
    return experienceListing.some(
      (exp) =>
        exp.job_title === newExperience.jobTitle &&
        exp.start_date === newExperience.startDate
    );
  };

  const isProjectExists = (newProject) => {
    return projectsListing.some(
      (proj) =>
        proj.project_name === newProject.projectName &&
        proj.start_date === newProject.startDate
    );
  };

  return (
    <div>
      {/* Experience */}
      <div>
        <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
          <div className="vertical-center gap-3">
            <LongArrow
              className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
              onClick={() => navigate(-1)}
            />
            <h3 className="text-xl">Experience</h3>
          </div>
          <div
            onClick={() => setOpenExperienceModal(true)}
            className="flex justify-center items-center gap-3 cursor-pointer"
          >
            <AddMore size={"size-7"} />
            <h3 className="text-xl">Add Experience</h3>
          </div>
        </div>

        <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
          <div className="flex flex-col gap-6 mt-4">
            {experienceLoading ? (
              <>
                {Array.from({ length: 2 }, (_, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b border-grey/30 last:border-0 pb-5"
                    >
                      <div className="font-nunito vertical-center justify-between">
                        <Skeleton className="h-5 w-52 rounded-full" />
                      </div>
                      <div className="vertical-center gap-2 text-sm text-grey mt-2">
                        <Skeleton className="h-4 w-12 rounded-full" />
                        <Skeleton className="h-4 w-12 rounded-full" />
                      </div>

                      <div className="vertical-center gap-2 text-sm text-grey mt-2">
                        <Skeleton className="h-4 w-16 rounded-full" />
                        <Skeleton className="h-4 w-16 rounded-full" />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {experienceListing?.length > 0 ? (
                  experienceListing?.map((experience, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="font-nunito vertical-center justify-between">
                          <h2>{experience?.job_title}</h2>
                          <div className="center gap-3">
                            <CustomPopover
                              open={openPopover[index]}
                              setOpen={() => handlePopoverToggle(index)}
                              placement="bottom-end"
                              trigger={
                                <div className="flex items-center gap-2">
                                  <DotsIcon />
                                </div>
                              }
                            >
                              <div className="gap-3 w-32 flex flex-col items-center bg-white p-2 rounded-md">
                                {/* View Icon */}
                                <IconNavigation
                                  icon={<EyeIcon width={22} height={22} />}
                                  text="View"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setOpenExperienceModal(true);
                                    setExperience({
                                      view: true,
                                      jobType: experience?.type
                                        .toLowerCase()
                                        .replace(/^\w/, (c) => c.toUpperCase()),
                                      jobTitle: experience?.job_title,
                                      company: experience?.employer,
                                      startDate: experience?.start_date,
                                      endDate: experience?.end_date
                                        ? experience?.end_date
                                        : "",
                                      isOngoing: experience?.is_ongoing
                                        ? experience?.is_ongoing
                                        : null,
                                      file: experience?.file_upload
                                        ? experience?.file_upload
                                        : null,
                                    });
                                  }}
                                  row
                                />

                                {/* Edit Icon */}
                                <IconNavigation
                                  icon={<EditIcon width={22} height={22} />}
                                  text="Edit"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setOpenExperienceModal(true);
                                    setExperience({
                                      edit: true,
                                      id: experience?.id,
                                      jobType: getJobTypeIdByName(
                                        experience?.type
                                          .split(" ")
                                          .map((word) =>
                                            word
                                              .toLowerCase()
                                              .replace(/^\w/, (c) =>
                                                c.toUpperCase()
                                              )
                                          )
                                          .join(" ")
                                      ),

                                      jobTitle: experience?.job_title,
                                      company: experience?.employer,
                                      startDate: experience?.start_date,
                                      endDate: experience?.end_date
                                        ? experience?.end_date
                                        : null,
                                      isOngoing: experience?.is_ongoing
                                        ? experience?.is_ongoing
                                        : null,
                                    });
                                  }}
                                  row
                                />

                                {/* Delete Icon */}
                                <IconNavigation
                                  icon={<DeleteIcon width={22} height={22} />}
                                  text="Delete"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setDeleteModal({
                                      open: true,
                                      data: {
                                        id: experience?.id,
                                        name: "Experience",
                                        onClick: deleteExperience,
                                      },
                                    });
                                  }}
                                  row
                                />
                              </div>
                            </CustomPopover>
                          </div>
                        </div>

                        {/* Employer and Job Type */}
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>
                            {experience?.employer
                              ?.split(" ")
                              ?.map((item) => capitalize(item))
                              ?.join(" ")}
                          </p>
                          {" | "}
                          <p>
                            {experience?.type
                              ?.toLowerCase()
                              ?.split(" ")
                              ?.map((item) => capitalize(item))
                              ?.join(" ")}
                          </p>
                        </div>

                        {/* Start and End Date */}
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(experience?.start_date)}</p>
                          {" - "}
                          <p>
                            {experience?.is_ongoing
                              ? "Present"
                              : formatDate(experience?.end_date)}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center font-semibold pb-3">
                    No Data Found
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Projects */}
      <div className="mb-6">
        <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
          <h3 className="text-xl">Projects</h3>

          <div
            onClick={() => setOpenProjectModal(true)}
            className="flex justify-center items-center gap-3 cursor-pointer"
          >
            <AddMore size={"size-7"} />
            <h3 className="text-xl">Add Project</h3>
          </div>
        </div>

        <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
          <div className="flex flex-col gap-6 mt-4">
            {projectsLoading ? (
              <>
                {Array.from({ length: 2 }, (_, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b border-grey/30 last:border-0 pb-5"
                    >
                      <div className="font-nunito vertical-center justify-between">
                        <Skeleton className="h-5 w-52 rounded-full" />
                      </div>
                      <div className="vertical-center gap-2 text-sm text-grey mt-2">
                        <Skeleton className="h-4 w-12 rounded-full" />
                        <Skeleton className="h-4 w-12 rounded-full" />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {projectsListing?.length > 0 ? (
                  projectsListing?.map((project, index) => {
                    const selectedState = statesListing.find(
                      (state) => state.name === project?.proj_exp_state
                    );
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="font-nunito vertical-center justify-between mb-1">
                          <h2>{capitalize(project?.project_name)}</h2>
                          <div className="center gap-3">
                            <CustomPopover
                              open={openProjectPopover[index]}
                              setOpen={() => handleProjectPopoverToggle(index)}
                              placement="bottom-end"
                              trigger={
                                <div className="flex items-center gap-2">
                                  <DotsIcon />
                                </div>
                              }
                            >
                              <div className="gap-3 w-32 flex flex-col items-center bg-white p-2 rounded-md">
                                {/* View Icon */}
                                <IconNavigation
                                  icon={<EyeIcon width={22} height={22} />}
                                  text="View"
                                  onClick={() => {
                                    handleProjectPopoverToggle(index);
                                    setOpenProjectModal(true);
                                    setProject({
                                      view: true,
                                      projectName: project?.project_name,
                                      city: project?.proj_exp_city,
                                      startDate: project?.start_date,
                                      endDate: project?.end_date
                                        ? project?.end_date
                                        : null,
                                      isOngoing: project?.is_ongoing
                                        ? project?.is_ongoing
                                        : null,
                                      file: project?.file_upload_url
                                        ? project?.file_upload_url
                                        : null,
                                    });
                                  }}
                                  row
                                />

                                {/* Edit Icon */}
                                <IconNavigation
                                  icon={<EditIcon width={22} height={22} />}
                                  text="Edit"
                                  onClick={() => {
                                    handleProjectPopoverToggle(index);
                                    setOpenProjectModal(true);
                                    setProject({
                                      edit: true,
                                      id: project?.id,
                                      projectName: project?.project_name,
                                      state: String(selectedState?.id),
                                      city: String(project?.location?.city),
                                      startDate: project?.start_date,
                                      endDate: project?.end_date
                                        ? project?.end_date
                                        : null,
                                      isOngoing: project?.is_ongoing
                                        ? project?.is_ongoing
                                        : null,
                                      file: project?.file_upload_url
                                        ? project?.file_upload_url
                                        : null,
                                    });
                                  }}
                                  row
                                />

                                {/* Delete Icon */}
                                <IconNavigation
                                  icon={<DeleteIcon width={22} height={22} />}
                                  text="Delete"
                                  onClick={() => {
                                    handleProjectPopoverToggle(index);
                                    setDeleteModal({
                                      open: true,
                                      data: {
                                        id: project?.id,
                                        name: "Project",
                                        onClick: deleteProject,
                                      },
                                    });
                                  }}
                                  row
                                />
                              </div>
                            </CustomPopover>
                          </div>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{project?.proj_exp_city}</p>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(project?.start_date)}</p>
                          {"-"}
                          <p>
                            {project?.is_ongoing
                              ? "Present"
                              : formatDate(project?.end_date)}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center font-semibold py-3">
                    No Data Found
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Experience Modal */}
      <CustomModal
        title={
          experience?.view
            ? "Experience Detail"
            : experience?.edit
            ? "Edit Experience"
            : "Add Experience"
        }
        open={openExperienceModal}
        close={handleCloseExperienceModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addExperience} className="space-y-4">
          <div className="flex flex-col gap-5">
            {!experience?.view ? (
              <>
                <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
                  <p className="font-bold text-sm text-primary">Experience</p>
                  {/* <p className="font-bold text-sm text-darkgray">*Required</p> */}
                </div>
                <div className="vertical-center gap-2">
                  <h2 className="text-lg font-bold text-gray-800">
                    Experience
                  </h2>
                  {experienceLoading ? <Spinner /> : null}
                </div>
              </>
            ) : null}

            <div className={`space-y-4`}>
              {experience?.view ? (
                <TextInput
                  label="Job Type"
                  name="jobTitle"
                  value={experience.jobType}
                  // onChange={handleExperienceChange}
                  disabled={true}
                />
              ) : (
                <SelectDropdown
                  options={jobTypeData}
                  label="Select Job Type"
                  name="jobType"
                  value={experience?.jobType}
                  onChange={handleExperienceChange}
                  required={true}
                />
              )}
              <TextInput
                label="Job Title"
                name="jobTitle"
                required={experience?.view ? false : true}
                value={experience.jobTitle}
                onChange={handleExperienceChange}
                disabled={experience?.view ? true : false}
              />
              <TextInput
                label="Company/Employer"
                name="company"
                value={experience.company}
                onChange={handleExperienceChange}
                required={experience?.view ? false : true}
                disabled={experience?.view ? true : false}
              />

              <div className="flex gap-4">
                <TextInput
                  label="Start Date"
                  name="startDate"
                  type="date"
                  value={experience.startDate}
                  onChange={handleExperienceChange}
                  max={new Date().toISOString().split("T")[0]}
                  required={experience?.view ? false : true}
                  disabled={experience?.view ? true : false}
                  error={dateError?.experience?.startDate}
                  errorMsg={dateError?.experience?.startDate}
                />
                {experience?.view ? (
                  <TextInput
                    label="End Date"
                    // name="endDate"
                    type="text"
                    value={
                      experience?.isOngoing ? "Present" : experience?.endDate
                    }
                    onChange={handleExperienceChange}
                    min={experience?.startDate}
                    max={new Date().toISOString().split("T")[0]}
                    required={experience?.view ? false : true}
                    disabled={true}
                  />
                ) : (
                  <TextInput
                    label="End Date"
                    name="endDate"
                    type="date"
                    value={experience?.endDate}
                    onChange={handleExperienceChange}
                    min={experience?.startDate}
                    // max={new Date().toISOString().split("T")[0]}
                    required={true}
                    disabled={experience?.isOngoing === true}
                    error={dateError?.experience?.endDate}
                    errorMsg={dateError?.experience?.endDate}
                  />
                )}
              </div>
              {experience?.view ? null : (
                <div className="pl-1 pb-1">
                  <CustomCheckbox
                    label="Ongoing"
                    checked={experience?.isOngoing}
                    onChange={handleExperienceCheckbox}
                    disabled={experience?.view ? true : false}
                  />
                </div>
              )}

              {experience?.view ? (
                experience?.file ? (
                  <p
                    onClick={() =>
                      experience?.file &&
                      window.open(experience?.file, "_blank")
                    }
                    className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                  >
                    <span className="pl-1">View file</span>
                  </p>
                ) : null
              ) : (
                <div className="mb-1">
                  <label
                    htmlFor="proofUpload"
                    className="block text-[14px] font-medium text-mediumgray"
                  >
                    {experience?.edit ? "Update document" : "Upload document"}
                  </label>
                  <input
                    type="file"
                    id="proofUpload"
                    name="proofUpload"
                    onChange={handleExperienceFileChange}
                    className="mt-1 block w-fit text-sm text-mediumgray"
                  />
                </div>
              )}
            </div>
            {!experience?.view ? (
              <AddMore
                text={experience?.edit ? "Update Experience" : "Add Experience"}
                onClick={addExperience}
                disabled={
                  experienceLoading
                    ? true
                    : isEmpty({
                        name: experience?.jobType,
                        state: experience?.jobTitle,
                        city: experience?.company,
                        startDate: experience?.startDate,
                        ...(experience?.isOngoing && {
                          isOnGoing: experience?.isOngoing,
                        }),
                        ...(!experience?.isOngoing && {
                          endDate: experience?.endDate,
                        }),
                      })
                }
              />
            ) : null}
          </div>
        </form>
      </CustomModal>

      {/* Projects Modal */}
      <CustomModal
        title={
          project?.view
            ? "Project Detail"
            : project?.edit
            ? "Edit Project"
            : "Add Project"
        }
        open={openProjectModal}
        close={handleCloseProjectModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addProject} className="space-y-4">
          <div className="flex flex-col gap-5">
            {project?.view ? (
              ""
            ) : (
              <>
                <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
                  <p className="font-bold text-sm text-primary">Projects</p>
                  {/* <p className="font-bold text-sm text-darkgray">*Required</p> */}
                </div>
                <h2 className="text-lg font-bold text-gray-800">Projects</h2>
              </>
            )}
            <div className={`space-y-4`}>
              <TextInput
                label="Project Name"
                name="projectName"
                value={project?.projectName}
                onChange={handleProjectChange}
                required={project?.view ? false : true}
                disabled={project?.view ? true : false}
              />
              {project?.view ? (
                <TextInput
                  label="City"
                  name="projectName"
                  value={project.city}
                  onChange={handleProjectChange}
                  required={project?.view ? false : true}
                  disabled={project?.view ? true : false}
                />
              ) : (
                <>
                  <SelectDropdown
                    options={statesListing}
                    label="Select State"
                    name="state"
                    value={project?.state}
                    onChange={handleProjectChange}
                    required={true}
                  />
                  <SelectDropdown
                    options={citiesList}
                    disabled={!project?.state}
                    label="Select City"
                    name="city"
                    value={project?.city}
                    onChange={handleProjectChange}
                    description={
                      cityLoading ? "Loading..." : "Please Select State First"
                    }
                    required={true}
                  />
                </>
              )}
            </div>

            <div className="flex gap-4">
              {!project?.view ? (
                <>
                  <TextInput
                    label="Start Date"
                    name="startDate"
                    type="date"
                    value={project.startDate}
                    onChange={handleProjectChange}
                    max={new Date().toISOString().split("T")[0]}
                    required={true}
                    error={dateError?.project?.startDate}
                    errorMsg={dateError?.project?.startDate}
                  />
                  <TextInput
                    label="End Date"
                    name="endDate"
                    type="date"
                    value={project.endDate}
                    onChange={handleProjectChange}
                    min={project?.startDate}
                    // max={new Date().toISOString().split("T")[0]}
                    required={true}
                    disabled={project?.isOngoing === true}
                    error={dateError?.project?.endDate}
                    errorMsg={dateError?.project?.endDate}
                  />
                </>
              ) : (
                <>
                  <TextInput
                    label="Start Date"
                    // name="projectName"
                    value={project.startDate}
                    onChange={handleProjectChange}
                    required={project?.view ? false : true}
                    disabled={project?.view ? true : false}
                    error={dateError?.project?.startDate}
                    errorMsg={dateError?.project?.startDate}
                  />
                  <TextInput
                    label="End Date"
                    // name="projectName"
                    value={project?.isOngoing ? "Present" : project?.endDate}
                    onChange={handleProjectChange}
                    required={project?.view ? false : true}
                    disabled={project?.view ? true : false}
                    error={dateError?.project?.endDate}
                    errorMsg={dateError?.project?.endDate}
                  />
                </>
              )}
            </div>
            {project?.view ? (
              ""
            ) : (
              <div className="pl-1">
                <CustomCheckbox
                  label="Ongoing"
                  checked={project.isOngoing}
                  onChange={handleCheckbox}
                />
              </div>
            )}

            {project?.view ? (
              project?.file ? (
                <p
                  onClick={() =>
                    project?.file && window.open(project?.file, "_blank")
                  }
                  className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                >
                  <span className="pl-1">View file</span>
                </p>
              ) : null
            ) : (
              <>
                <div className="mb-1">
                  <label
                    htmlFor="proofUpload"
                    className="block text-[14px] font-medium text-mediumgray"
                  >
                    {project?.edit ? "Update document" : "Upload document"}
                  </label>
                  <input
                    type="file"
                    id="proofUpload"
                    name="proofUpload"
                    onChange={handleProjectFileChange}
                    className="mt-1 block w-fit text-sm text-mediumgray"
                  />
                </div>
                <AddMore
                  text={project?.edit ? "Update Project" : "Add Project"}
                  onClick={addProject}
                  disabled={
                    projectsLoading
                      ? true
                      : isEmpty({
                          name: project?.projectName,
                          state: project?.state,
                          city: project?.city,
                          startDate: project?.startDate,
                          ...(project?.isOngoing && {
                            isOnGoing: project?.isOngoing,
                          }),
                          ...(!project?.isOngoing && {
                            endDate: project?.endDate,
                          }),
                        })
                  }
                />
              </>
            )}
          </div>
        </form>
      </CustomModal>

      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={
          deleteModal?.data?.name === "Project"
            ? projectsLoading
            : experienceLoading
        }
      />
    </div>
  );
};
