import { AddMore } from "../AddMore";

export const UploadImageButton = ({
  maxImages,
  handleImageUpload,
  errorMessage,
  setErrorMessage,
}) => {
  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > maxImages) {
      setErrorMessage(`You can upload up to ${maxImages} images only.`);
      return;
    }

    setErrorMessage("");
    handleImageUpload(event);
  };

  return (
    <>
      <div className="relative">
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileSelect}
          className="hidden"
          id="image-upload"
        />

        <div
          className="flex justify-center items-center gap-3 pt-3 cursor-pointer"
          onClick={() => {
            const fileInput = document.getElementById("image-upload");
            if (fileInput) {
              fileInput.value = "";
              fileInput.click();
            }
          }}
        >
          <AddMore size={"size-7"} />
          <p className="text-lg font-semibold">Upload Images</p>
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-500 w-full text-sm text-end mt-1 absolute left-0 right-5 top-full bottom-1 whitespace-nowrap">
          {errorMessage}
        </p>
      )}
    </>
  );
};
