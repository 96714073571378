/* eslint-disable react-hooks/exhaustive-deps */
// Library Imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Input } from "@nextui-org/react";

// Local Imports
import {
  DocumentIcon,
  GroupChatIcon,
  ImageIcon,
  SendIcon,
} from "../../../assets/icons";
import SearchBar from "../../../components/shared/SearchBar";
import { Button, showToast } from "../../../components/shared";
import SideBarBody from "../../../components/Chats/SideBarBody";
import ChatAreaHeader from "../../../components/Chats/ChatAreaHeader";
import { assets } from "../../../assets/images";
import { styles } from "../../../components/shared/AuthLayout";
import ChatAreaBody from "../../../components/Chats/ChatAreaBody";
import GroupChatModal from "../../../components/Chats/GroupChatModal";
import { useSocket } from "../../../redux/socket/socket";
import { formatToReadableTime } from "../../../utils/utils";
import axiosInstance from "../../../redux/network/https";
import { endPoints } from "../../../redux/network/constant";

const Chats = () => {
  const {
    channels,
    messages,
    newChannel,
    sendCommand,
    setMessages,
    isSocketReady,
  } = useSocket();
  const parterns = useSelector((state) => state?.partners?.partners);
  const state = useLocation();
  const [activeChat, setActiveChat] = useState(null);
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);
  // const [openPopover, setOpenPopover] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [openGroupChatModal, setOpenGroupChatModal] = useState(false);
  const scrollRef = useRef();
  const inputRef = useRef();
  const fileInputRef = useRef();
  const [allUsersMembers, setAllUsersMembers] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);

  // Search Chats Function
  const handleChatSearch = useCallback(
    (value) => {
      if (!value) {
        setSearchQuery(value);
        setSearchUsers([]);
      } else {
        const searchListingArray = allUsersMembers
          ?.filter((user) =>
            user?.name?.toLowerCase()?.includes(value?.toLowerCase())
          )
          ?.map((search) => {
            const channelId =
              channels?.find(
                (channel) =>
                  channel?.channel_type !== "group" &&
                  channel?.members[0]?.user_id === search?.id
              )?.channel_id || null;
            return {
              ...search,
              channel_id: channelId,
              completeItem: {
                ...search?.completeItem,
                channel_id: channelId,
              },
            };
          });
        setSearchQuery(value);
        setSearchUsers([...searchListingArray]);
      }
    },
    [allUsersMembers, channels]
  );

  // Get All Chat Users Listing
  const getAllUsers = () => {
    const users = [
      ...(parterns?.matched_partners || []),
      ...(parterns?.prioritized_partners || []),
    ]?.map((data) => {
      const partner = data?.partner || data?.user || {};
      return {
        id: partner?.id || null,
        name: `${partner?.first_name || ""} ${partner?.last_name || ""}`.trim(),
        avatar: partner?.profile_image || "",
        // online: Boolean(partner?.status),
        selected: false,
        completeItem: data,
      };
    });
    setAllUsersMembers(users);
  };

  // Fetch All Chats
  const fetchAllChats = () => {
    if (isSocketReady) {
      sendCommand("fetch_channels");
    } else {
      console.warn("WebSocket not ready. Retrying...");
    }
  };

  // Fetch All Chats
  const markSeenMessage = (channelId) => {
    if (isSocketReady) {
      sendCommand("mark_seen_messages", { channel_id: channelId });
      fetchAllChats();
    } else {
      console.log("🚀 ~ else");
      console.warn("WebSocket not ready. Retrying...");
    }
  };

  // sendMessage
  const sendMessage = (attachements) => {
    if (isSocketReady) {
      const file = attachements?.fileUrl
        ? [
            {
              file_url: attachements?.fileUrl,
              file_name: attachements?.fileName,
              file_type: attachements?.fileType,
            },
          ]
        : [];
      const fileType = attachements?.fileType?.startsWith("image/")
        ? "image"
        : attachements?.fileType?.startsWith("video/")
        ? "video"
        : "document";
      if (activeChat?.channel_id) {
        sendCommand("new_message", {
          message: file?.length > 0 ? fileType : message,
          channel_id: activeChat?.channel_id,
          attachments: file,
        });
        activeChat?.channel_type === "group" &&
          fetchChatMessages(activeChat?.channel_id);
      } else {
        sendCommand("new_channel", {
          channel_type: activeChat?.chatType,
          members: [activeChat?.id],
          message: file?.length > 0 ? fileType : message,
          attachments: file,
        });
      }
    }
    setMessage("");
  };

  // Fetch Chat Messages
  const fetchChatMessages = (channelId) => {
    if (isSocketReady) {
      sendCommand("fetch_messages", { channel_id: channelId });
    } else {
      console.warn("WebSocket not ready. Retrying...");
    }
  };

  // Create New Group Chat
  const createNewGroupChat = (ids, payload) => {
    sendCommand("new_channel", {
      channel_type: "group",
      members: ids,
      group_name: payload?.groupName,
      sub_line: payload?.groupDescription,
    });
    fetchAllChats();
  };

  // Delete Chat
  const deleteChat = (channelId) => {
    console.log("🚀 ~ mai chal raha hun", activeChat);
    sendCommand("delete_channel", { channel_id: channelId });
    setActiveChat(null);
  };

  // Handle File Upload
  const handleFileUpload = (type) => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = type;
      fileInputRef.current.click();
    }
  };

  const handleFileSelected = (e) => {
    const file = e.target.files?.[0];
    if (file && file?.size > 10 * 1024 * 1024) {
      showToast("File size should be less than 10MB", "error", {
        position: "top-center",
      });
      return;
    }
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      let mediaType;
      console.log("File URL:", fileUrl);
      console.log("Media Type:", mediaType); 
      if (file.type.startsWith("image/")) {
        mediaType = "image";
      } else if (file.type.startsWith("video/")) {
        mediaType = "video";
      } else {
        mediaType = "document";
      }
      setFileLoading(true);
      axiosInstance
        .post(endPoints.getMediaURL, {
          files: [
            {
              file_name: file.name,
              file_type: file.type,
            },
          ],
        })
        .then((res) => {
          const response = res?.data?.data[0];
          const formData = new FormData();
          formData.append("Content-Type", response?.fields?.["Content-Type"]);
          formData.append("key", response?.fields?.key);
          formData.append(
            "AWSAccessKeyId",
            response?.fields?.["AWSAccessKeyId"]
          );
          formData.append("policy", response?.fields?.policy);
          formData.append("signature", response?.fields?.signature);
          formData.append("file", file);
          axios
            .post(endPoints.saveMediaOnS3Bucket, formData)
            .then((res) => {
              sendMessage({
                fileName: response?.file_name,
                fileType: response?.file_type,
                fileUrl: response?.file_url,
              });
              setFileLoading(false);
            })
            .catch((err) => {
              console.log("🚀 ~ axios.post ~ err:", err);
              // if (err?.status === 204) {
              //     console.log("🚀 ~ axios.post ~ err:", err)
              //     sendMessage({ fileName: response?.file_name, fileType: response?.file_type, fileUrl: response?.file_url });
              setFileLoading(false);
              showToast(err?.response?.data?.message, "error", {
                position: "top-center",
              });
              // }
            });
        })
        .catch((err) => {
          setFileLoading(false);
          showToast(err?.response?.data?.message, "error", {
            position: "top-center",
          });
        });
    }
    
  };

  useEffect(() => {
    if (isSocketReady) {
      getAllUsers();
      fetchAllChats();
    }
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [isSocketReady, messages]);

  useEffect(() => {
    if (activeChat) {
      setActiveChat({
        ...activeChat,
        channel_id: newChannel,
      });
    }
  }, [newChannel]);

  useEffect(() => {
    if (state?.state?.user_id) {
      const userChat = channels?.find(
        (channel) =>
          channel?.channel_type === "individual" &&
          channel?.members[0]?.user_id === state?.state?.user_id
      );
      if (userChat) {
        setActiveChat(userChat);
        fetchChatMessages(userChat.channel_id);
        markSeenMessage(userChat.channel_id);
      } else {
        setActiveChat(state?.state?.completeItem);
        setSearchQuery(null);
      }
    }
    window.history.replaceState(null, "", window.location.pathname);
  }, [state]);

  return (
    <React.Fragment>
      {
        <div className="relative flex h-[calc(100vh-5.1rem)] my-2 bg-white overflow-x-hidden">
          {/* Inset */}
          {fileLoading && (
            <div className="inset-0 h-full w-full opacity-60 absolute z-50 flex items-center justify-center bg-gray-300">
              <div className="flex gap-2">
                <div className="w-4 h-4 rounded-full animate-pulse bg-blue-500"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-blue-500"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-blue-500"></div>
              </div>
            </div>
          )}

          {/* Chat Sidebar */}
          <div
            className={`border-r flex flex-col ${
              activeChat
                ? "hidden base:block w-[320px]"
                : "w-full base:w-[320px]"
            }`}
          >
            {/* Siderbar Header */}
            <div className="h-[70px] p-4 border-b flex items-center justify-between">
              <h1 className="text-xl font-semibold flex items-center gap-2">
                Active Conversations
                <span className="bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-sm">
                  {channels?.length}
                </span>
              </h1>
              {/* <CustomPopover
                            open={openPopover}
                            setOpen={() => setOpenPopover(!openPopover)}
                            trigger={
                                <div className="ml-auto  text-gray-400">
                                    <DotsIcon />
                                </div>
                            }
                            placement='bottom-end'
                        >
                            <div className="bg-white border border-gray-200 hover:bg-gray-100 py-2 px-2  rounded-md font-nunito text-sm flex flex-col gap-2"> */}
              <div
                className="w-10 h-10 rounded-full bg-mediumgrey/30 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setOpenGroupChatModal(true);
                }}
              >
                {/* setOpenPopover(false) }} */}
                {/* <i className="fas fa-info-circle text-green-500"></i> */}
                {/* <p>Add New Group Chat</p> */}
                <GroupChatIcon className="!w-6 !h-6" />
              </div>
              {/* </div>
                        </CustomPopover> */}
            </div>
            {/* Sidebar Body */}
            <React.Fragment>
              <div className="p-4">
                <SearchBar
                  className={"bg-gray-100 rounded"}
                  variant={"bordered"}
                  value={searchQuery || ""}
                  onValueChange={handleChatSearch}
                  placeholder={"Search"}
                  onClear={() => {
                    setSearchQuery("");
                  }}
                />
              </div>

              <div className="h-[calc(100vh-14rem)] flex-1 overflow-y-auto overflow-x-hidden">
                {(searchQuery?.length > 0 ? searchUsers : channels).length >
                0 ? (
                  (searchQuery?.length > 0 ? searchUsers : channels).map(
                    (item, index) => (
                      <SideBarBody
                        key={index}
                        avatar={item?.avatar}
                        lastMessage={item?.chat_message}
                        name={item?.channel_name || item?.name}
                        unReadMessages={item?.unread_messages}
                        status={item?.online}
                        setActiveChat={setActiveChat}
                        activeChat={activeChat}
                        completeItem={item}
                        fetchChatMessages={fetchChatMessages}
                        fetchAllChats={fetchAllChats}
                        setSearchQuery={setSearchQuery}
                        newChat={searchQuery?.length > 0}
                        markSeenMessage={markSeenMessage}
                        chatType={item?.channel_type}
                        setMessage={setMessage}
                        setMessages={setMessages}
                        inputRef={inputRef}
                      />
                    )
                  )
                ) : (
                  <div className="flex flex-col items-center justify-center h-full px-2 text-center bg-gray-100">
                    {channels?.length === 0 ? (
                      <>
                        <h3>No Conversations Yet</h3>
                        <p>Start a new conversation by searching for a user</p>
                      </>
                    ) : (
                      <p>No Member found for Chat!</p>
                    )}
                  </div>
                )}
              </div>
            </React.Fragment>
          </div>

          {/* Chat Area */}
          <React.Fragment>
            {activeChat === null ? (
              <div
                style={{
                  backgroundImage: `url(${assets.background})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
                className="w-[calc(100%-320px)] h-full overflow-hidden hidden base:block"
              >
                <div
                  style={styles}
                  className="h-full flex-1 flex flex-col items-center justify-center"
                >
                  <div className="px-4 xs:pl-8 sm:pl-12 smd:pl-16 base:pl-20 xl:pl-28 flex flex-col items-center sm:items-start text-center sm:text-left font-nunito">
                    <p className="text-white text-2xl sm:text-2xl base:text-3xl w-fit">
                      Welcome to
                    </p>
                    <div className="font-nunito flex items-baseline justify-center my-4">
                      <img
                        src={assets.logo}
                        alt="logo"
                        className="size-20 sm:size-24 base:size-32"
                      />
                      <p className="text-primary mt-3 base:text-5xl font-bold tracking-wide w-fit">
                        ealNest Chats.
                      </p>
                    </div>
                    <p className="text-primary mt-5 ml-8 text-lg sm:text-xl font-light tracking-wide w-fit">
                      Please select a conversation to start a chat.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`${
                  activeChat
                    ? "w-full h-full flex-1 flex flex-col"
                    : "hidden base:block"
                }`}
              >
                <React.Fragment>
                  {/* Chat Header */}
                  <ChatAreaHeader
                    chatAvatar={activeChat?.avatar}
                    chatType={
                      activeChat?.channel_type === "group" ? "group" : "single"
                    }
                    chatHeaderName={
                      activeChat?.channel_name || activeChat?.name
                    }
                    chatMembers={activeChat?.members?.map(
                      (member) => member?.user_name
                    )}
                    setActiveChat={setActiveChat}
                    activeChat={activeChat}
                    deleteChat={deleteChat}
                  />

                  {/* Chat Body/ Messages */}
                  <div className="h-[calc(100vh-14rem)] flex-1 overflow-y-auto p-4 space-y-4">
                    {activeChat?.channel_id &&
                      messages?.length > 0 &&
                      messages
                        ?.filter((item) => item?.body?.length > 0)
                        ?.map((item, index) => {
                          return (
                            <React.Fragment key={index + 1}>
                              <ChatAreaBody
                                message={item?.body}
                                name={item?.sender_name}
                                avatar={item?.sender?.avatar}
                                isSeenMessage={item?.is_seen}
                                messageTime={formatToReadableTime(
                                  item?.msg_time
                                )}
                                senderId={item?.sender_id}
                                chatType={
                                  activeChat?.channel_type === "group"
                                    ? "group"
                                    : "single"
                                }
                                activeChat={activeChat}
                                markSeenMessage={markSeenMessage}
                                onHover={() => {
                                  if (
                                    Number(
                                      channels?.find(
                                        (channel) =>
                                          channel?.channel_id ===
                                          activeChat?.channel_id
                                      )?.unread_messages
                                    ) > 0
                                  ) {
                                    markSeenMessage(activeChat?.channel_id);
                                  }
                                }}
                                file={
                                  item?.attachments?.length > 0 ? true : false
                                }
                                fileType={
                                  item?.attachments?.length > 0
                                    ? item?.attachments[0]?.file_type
                                    : null
                                }
                                attachements={
                                  item?.attachments?.length > 0
                                    ? item?.attachments[0]
                                    : null
                                }
                                completeItem={item}
                              />
                            </React.Fragment>
                          );
                        })}
                    <div ref={scrollRef} />
                  </div>
                </React.Fragment>

                {/* Chat Footer/ Input */}
                <div className="p-4 border-t w-full">
                  <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-2">
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      onChange={handleFileSelected}
                      onClick={(e) => {
                        e.target.value = null;
                        setMessage("");
                      }}
                    />
                    <button
                      className="p-2 hover:bg-gray-100 rounded-full"
                      onClick={() =>
                        handleFileUpload(
                          "image/jpeg, image/jpeg, image/png, image/gif, image/bmp, image/svg+xml, image/webp,video/mp4, video/quicktime, video/x-msvideo, video/x-matroska, video/webm, video/x-flv, video/x-ms-wmv"
                        )
                      }
                    >
                      <ImageIcon className="w-5 h-5 text-gray-500" />
                    </button>
                    <button
                      className="hover:bg-gray-100 rounded-full"
                      onClick={() =>
                        handleFileUpload(
                          "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, text/csv, application/json, application/xml"
                        )
                      }
                    >
                      <DocumentIcon className="w-5 h-5 text-gray-500" />
                    </button>
                    <Input
                      placeholder="Type something here"
                      classNames={{
                        inputWrapper:
                          "bg-transparent focus:ring-0 !shadow-none !outline-none border-none",
                      }}
                      className="!w-[calc(100%-40px)]"
                      size="sm"
                      value={message}
                      ref={inputRef}
                      onChange={(e) => setMessage(e.target.value)}
                      onClick={() => {
                        if (
                          Number(
                            channels?.find(
                              (channel) =>
                                channel?.channel_id === activeChat?.channel_id
                            )?.unread_messages
                          ) > 0
                        ) {
                          markSeenMessage(activeChat?.channel_id);
                        }
                      }}
                      onKeyDown={(e) =>
                        message?.length > 0 &&
                        e.key === "Enter" &&
                        sendMessage()
                      }
                    />
                    <Button
                      className={`${
                        message?.length > 0
                          ? "opacity-100"
                          : "opacity-40 !bg-blue-900 hover:!bg-blue-900"
                      } !bg-primary hover:!bg-primary !p-2 !rounded !min-h-9 !w-[40px]`}
                      disabled={message?.length === 0}
                      onClick={message?.length > 0 && sendMessage}
                    >
                      <SendIcon className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>

          {/* Group Chats Modal */}
          <GroupChatModal
            allUsersMembers={allUsersMembers}
            openGroupChatModal={openGroupChatModal}
            setAllUsersMembers={setAllUsersMembers}
            setOpenGroupChatModal={setOpenGroupChatModal}
            createNewGroupChat={createNewGroupChat}
          />
        </div>
      }
    </React.Fragment>
  );
};

export default Chats;
