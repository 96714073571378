import React from "react";
import { assets } from "../../../assets/images";
import { styles } from "../AuthLayout";

export const ComingSoon = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center h-[calc(100vh-100px)] w-full">
      <div
        style={{
          backgroundImage: `url(${assets.background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
        className="w-[100vw] h-full overflow-hidden"
      >
        <div
          style={styles}
          className="h-full flex-1 flex flex-col items-center justify-center"
        >
          <div className="px-4 xs:pl-8 sm:pl-12 smd:pl-16 base:pl-20 xl:pl-28 flex flex-col items-center sm:items-start text-center sm:text-left font-nunito">
            <div className="font-nunito flex items-baseline justify-center my-4 opacity-30">
              <img
                src={assets.logo}
                alt="logo"
                className="size-20 sm:size-24 base:size-32"
              />
              <p className="text-primary mt-3 base:text-5xl font-bold tracking-wide w-fit">
                ealNest.
              </p>
            </div>
            <p className="text-white text-2xl sm:text-2xl base:text-3xl w-fit">
              Feature coming soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
