import React from "react";
import { CustomModal } from "../Modal";
import { formatDate } from "../../../utils/utils";
export const DetailModal = ({ title, data, open, onClose }) => {
  if (!data) return null;

  return (
    <CustomModal
      title={`${title} Details`}
      open={open}
      close={onClose}
      showPrimaryButton={false}
      showSecondaryButton={false}
    >
      {Object.keys(data).map((dataKey) => {
        const value = data[dataKey];

        // Skip fields that contain "id"
        if (!value || dataKey.toLowerCase().includes("id")) return null;

        // Skip verification field when title is Education
        if (
          title.toLowerCase() === "education" &&
          dataKey.toLowerCase() === "verification"
        )
          return null;
        // Determine label
        let label = dataKey.charAt(0).toUpperCase() + dataKey.slice(1);
        if (dataKey.toLowerCase() === "file_upload_url") label = "File";
        if (
          dataKey.toLowerCase() === "certificate_url" ||
          dataKey.toLowerCase() === "license_url"
        )
          label = "Link";

        return (
          <div key={dataKey} className="border-b pb-2 mb-2">
            <strong className="text-gray-700 block">{label}:</strong>

            {/* Handle file links */}
            {dataKey.toLowerCase() === "file_upload_url" ? (
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
              >
                <span className="pl-1">View File</span>
              </a>
            ) : /* Handle certificate/license links */
            dataKey.toLowerCase() === "certificate_url" ||
              dataKey.toLowerCase() === "license_url" ? (
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
              >
                <span className="pl-1">View Link</span>
              </a>
            ) : /* Handle object values (nested properties) */
            typeof value === "object" ? (
              <div className="mt-2">
                {Object.entries(value).map(([nestedKey, nestedValue]) => (
                  <p key={nestedKey} className="text-gray-600">
                    <strong>
                      {nestedKey.charAt(0).toUpperCase() + nestedKey.slice(1)}:
                    </strong>{" "}
                    {nestedValue}
                  </p>
                ))}
              </div>
            ) : dataKey === "start_date" ||
              dataKey === "end_date" ||
              dataKey === "date" ? (
              <p className="text-gray-600">{formatDate(value)}</p>
            ) : (
              /* Show regular text values */
              <p className="text-gray-600">{value}</p>
            )}
          </div>
        );
      })}
    </CustomModal>
  );
};
