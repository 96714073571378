import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePortfolioImageFunction,
  getPortfolioImagesFunction,
} from "../../../redux/reducers/userReducers/PortfolioImagesReducer/PortfolioImagesReducer";
import { Spinner } from "../Spinner";

export const Carousel = ({ setErrorMessage, partnerDetailsimages }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const { portfolioImages, loading } = useSelector(
    (state) => state.portfolioImages
  );
  const [imageLoading, setImageLoading] = useState({});

  useEffect(() => {
    if (!partnerDetailsimages) {
      dispatch(getPortfolioImagesFunction());
    }
  }, [dispatch, partnerDetailsimages]);

  const deleteImage = async (id) => {
    if (partnerDetailsimages) return;

    const data = await dispatch(deletePortfolioImageFunction(id));

    if (data?.meta?.requestStatus === "rejected") {
      setErrorMessage(data?.payload || "Something went wrong");
      return;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      dispatch(getPortfolioImagesFunction());
    }
  };

  const handleImageLoad = (id) => {
    setImageLoading((prev) => ({ ...prev, [id]: false }));
  };

  const imagesToRender = partnerDetailsimages || portfolioImages || [];

  return (
    <div className={imagesToRender.length ? "pt-5" : ""}>
      <div className="relative flex overflow-x-auto space-x-4 no-scrollbar">
        {imagesToRender.map((item, index) => {
          const id = item.id || index;
          const imageSrc =
            typeof item === "string"
              ? item
              : item.image_url || item.portfolio_image;

          return (
            <div key={id} className="relative flex-shrink-0 w-1/3 h-60 group">
              {loading && (
                <div className="absolute inset-0 flex justify-center items-center bg-gray-200">
                  <Spinner className="text-secondary size-10" />
                </div>
              )}
              <img
                src={imageSrc}
                alt={`Uploaded ${index}`}
                className={`w-full h-full object-cover rounded-lg shadow-md cursor-pointer ${
                  imageLoading[id] ? "opacity-0" : "opacity-100"
                }`}
                onLoad={() => handleImageLoad(id)}
                onClick={() => setSelectedImage(imageSrc)}
              />
              {!partnerDetailsimages && typeof item !== "string" && (
                <button
                  onClick={() => deleteImage(item.id)}
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center opacity-0 group-hover:opacity-100"
                >
                  &times;
                </button>
              )}
            </div>
          );
        })}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg overflow-hidden w-[90vw] h-[90vh] relative">
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};
