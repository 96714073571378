import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// Chart.js register
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ yearlyData, availableYears }) => {
    const [selectedYear, setSelectedYear] = useState(availableYears?.[0] || "");
    const maxValueInGraph = Math.max(...Object.values(yearlyData)?.flatMap(year => Object.values(year)?.flat()))
    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Active Users",
                data: yearlyData[selectedYear]?.active || [],
                backgroundColor: "#1E009F",
            },
            {
                label: "Inactive Users",
                data: yearlyData[selectedYear]?.inactive || [],
                backgroundColor: "#2964E2",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: true, position: "bottom" },
            title: { display: false },
        },
        // maxValue : 
        scales: {
            y: {
                max: Number(maxValueInGraph) + 10,
            },
        },
    };

    return (
        <div className="">
            {/* Year Filter Dropdown */}
            {availableYears?.length > 0 &&
                <div className="flex items-center justify-end mb-4">
                    <label className="mr-2 text-gray-600 font-medium">Select Year:</label>
                    <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        className="cursor-pointer px-2 py-1 border rounded-lg
                    focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                    >
                        {availableYears?.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            }

            {/* Bar Chart */}
            <div className="h-[400px]">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
};

export default BarChart;
