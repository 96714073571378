import React, { useCallback, useState } from "react";
import { CustomModal, TextInput } from "../shared";
import SearchBar from "../shared/SearchBar";
import GroupMemberSelection from "./GroupMemberSelection";

const GroupChatModal = ({
  openGroupChatModal,
  setOpenGroupChatModal,
  allUsersMembers,
  setAllUsersMembers,
  createNewGroupChat,
}) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchUserListing, setSearchUserListing] = useState([]);
  const [groupDetails, setGroupDetails] = useState({
    groupName: "",
    groupDescription: "",
  });

  const handleChatSearch = useCallback(
    (value) => {
      if (!value) {
        setSearchQuery(value);
        setSearchUserListing([]);
      } else {
        const searchListingArray = allUsersMembers?.filter((user) =>
          user?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
        setSearchQuery(value);
        setSearchUserListing([...searchListingArray]);
      }
    },
    [allUsersMembers]
  );

  // Create New Group Chat
  const handleCreateGroupChat = () => {
    const ids = allUsersMembers
      ?.filter((item) => item?.selected)
      ?.map((item) => item?.id);
    createNewGroupChat(ids, groupDetails);
    setOpenGroupChatModal(false);
    setGroupDetails({
      groupName: "",
      groupDescription: "",
    });
    setAllUsersMembers((prev) =>
      prev.map((item) => ({ ...item, selected: false }))
    );
    setSearchQuery(null);
  };

  return (
    <div>
      <CustomModal
        title={"Add New Chat"}
        open={openGroupChatModal}
        close={() => {
          setOpenGroupChatModal(false);
          setGroupDetails({ groupName: "", groupDescription: "" });
          setAllUsersMembers((prev) =>
            prev.map((item) => ({ ...item, selected: false }))
          );
          setSearchQuery(null);
        }}
        showPrimaryButton={true}
        showSecondaryButton={false}
        primaryButtonColor="primary"
        primaryButtonDisabled={
          allUsersMembers?.filter((item) => item?.selected)?.length >= 2 &&
          groupDetails?.groupName?.length > 0 &&
          groupDetails?.groupDescription?.length > 0
            ? false
            : true
        }
        primaryButtonText={"Create Group"}
        primaryButtonClick={() => handleCreateGroupChat()}
      >
        <div className="!h-[calc(100vh-290px)] overflow-hidden">
          <div className="">
            <TextInput
              label="Add Chat Name"
              name="groupName"
              value={groupDetails?.groupName}
              onChange={(e) =>
                setGroupDetails({ ...groupDetails, groupName: e.target.value })
              }
              required={true}
            />
          </div>
          <div className="pt-3">
            <TextInput
              label="Add Chat Description"
              name="groupDescription"
              value={groupDetails?.groupDescription}
              onChange={(e) =>
                setGroupDetails({
                  ...groupDetails,
                  groupDescription: e.target.value,
                })
              }
              required={true}
            />
          </div>
          <div className="h-full">
            <div className="py-3">
              <SearchBar
                className={"bg-gray-100 rounded"}
                variant={"bordered"}
                value={searchQuery || ""}
                onValueChange={handleChatSearch}
                placeholder={"Search"}
                onClear={() => {
                  setSearchQuery("");
                }}
              />
            </div>

            <div className="!h-[calc(100%-180px)] flex-1 !overflow-y-auto overflow-x-hidden">
              {(searchQuery?.length > 0
                ? searchUserListing
                : allUsersMembers
              )?.map((item, index) => (
                <GroupMemberSelection
                  key={index + 1}
                  name={item?.name}
                  avatar={item?.avatar}
                  currentIndex={index}
                  searchUserListing={searchUserListing}
                  setSearchUserListing={setSearchUserListing}
                  allUsersMembers={allUsersMembers}
                  setAllUsersMembers={setAllUsersMembers}
                  completeItem={item}
                  isSearchActive={searchQuery?.length > 0}
                />
              ))}
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default GroupChatModal;
