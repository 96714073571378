// Stagging Base URLS
export const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiVersion = "v1";
const domain = baseURL + apiVersion;

// EndPoints
export const endPoints = {
  // Register User
  Register: `${domain}/registration/`,

  // Register User
  ForgetPassword: `${domain}/forget-password/`,

  // Register User
  ResetPassword: `${domain}/change-password/`,

  // Login User
  Login: `${domain}/login/`,

  // Roles & Inetrests
  RolesInterests: `${domain}/roles-interests/`,

  // States
  States: `${domain}/fetch-states/`,

  // Users
  Users: `${domain}/users/`,

  // User Detail
  GetProfileDetails: `${domain}/personal-info-detail/`,

  // Update User Detail
  UpdateProfileDetail: `${domain}/personal-info-update/`,

  // Skills
  Skills: `${domain}/user-skill/`,

  // Job Experience
  JobExperience: `${domain}/job-experience/`,

  // Project Experience
  ProjectExperience: `${domain}/project-experience/`,

  // Certidications
  Certifications: `${domain}/user-certificate/`,

  //Lisense
  License: `${domain}/user-license/`,

  // Recommendation
  Recommendation: `${domain}/user-recommendation/`,

  // Education
  Education: `${domain}/user-education/`,

  // Education
  SignupSuggestions: `${domain}/suggested-partners/`,

  // Partner Details
  partnerDetails: `${domain}/partner-detail/`,

  // Partner Status
  partnerStatus: `${domain}/partners/`,

  // Partners Listing
  partnerListing: `${domain}/interested-partners/`,

  // User Verification
  Verify: `${domain}/verify/`,

  // Top Rated
  TopRatedPartners: `${domain}/top_rated_partners/`,

  // Suggested Partners Details Api
  SuggestedPartnersDetails: `${domain}/suggested_partner_detail/`,

  // partner filter
  partnerFilter: `${domain}/partner/filter/`,

  // Chat/GetMediaURL
  getMediaURL: `${domain}/chat/media_url/`,

  // saveMediaOnS3Bucket
  saveMediaOnS3Bucket: `https://dealnest-storage.s3.amazonaws.com/`,

  // profile image
  profileImg: `${domain}/upload-profile-image/`,

  // Profile progress
  profileCompletion: `${domain}/profile_completion_percentage/`,

  PortfolioImages: `${domain}/portfolio-images/`,
};
