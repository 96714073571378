/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  showToast,
  Skeleton,
  Spinner,
  TextInput,
  CustomPopover,
  IconNavigation,
} from "../../../../components/shared";
import { CustomModal } from "../../../../components/shared";
import {
  DeleteIcon,
  LongArrow,
  DotsIcon,
  EyeIcon,
  EditIcon,
} from "../../../../assets/icons";
import { AddMore } from "../../../../components/shared/AddMore";
import {
  deleteLicensesFunction,
  getLicensesFunction,
  updateLicensesFunction,
} from "../../../../redux/reducers/userReducers/licensesReducer/licensesReducer";
import { capitalize, formatDate } from "../../../../utils/utils";
import { isEmpty } from "../../../../utils/validations";
import { ProfileDeleteModal } from "../DeleteModal";

export const Licenses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.licenses);
  const [openLicencesModal, setOpenLicencesModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [dateError, setDateError] = useState("");
  const [license, setLicense] = useState({
    licenseName: "",
    licenseNumber: "",
    link: "",
    date: "",
    organization: "",
    proofUpload: null,
    view: false,
  });
  const [licensesList, setLicensesList] = useState([]);
  const [openPopover, setOpenPopover] = useState({});

  const handleCloseModal = () => {
    setOpenLicencesModal(false);
    setLicense({
      licenseName: "",
      link: "",
      date: "",
      organization: "",
      proofUpload: null,
      view: false,
    });
  };

  const getLicenses = async () => {
    const data = await dispatch(getLicensesFunction());
    setLicensesList(data?.payload);
  };

  const deleteLicenses = async (id) => {
    const data = await dispatch(
      deleteLicensesFunction({
        id: id,
      })
    );
    if (data?.meta?.requestStatus === "fulfilled") {
      setDeleteModal(false);
      showToast("License Deleted Successfully", "success", {
        position: "top-center",
      });
      setLicense("");
      getLicenses();
    }
    if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join())?.join("\n"),
        {
          position: "top-center",
        }
      );
    }
  };

  useLayoutEffect(() => {
    getLicenses();
  }, []);

  const addLicense = async () => {
    const formData = new FormData();
    formData.append("name", license?.licenseName);
    formData.append("license_url", license?.link);
    formData.append("date", license?.date);
    formData.append("organization", license?.organization);
    if (license?.proofUpload) {
      formData.append("file_upload", license.proofUpload);
    }
    if (license?.licenseNumber) {
      formData.append("license_num", license.licenseNumber);
    }

    const id = license?.id;
    const data = await dispatch(updateLicensesFunction({ formData, id }));
    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenLicencesModal(false);
      showToast(
        license?.id
          ? "License updated Successfully"
          : "License Added Successfully",
        "success",
        {
          position: "top-center",
        }
      );
      setLicense("");
      getLicenses();
    }
    if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join())?.join("\n"),
        {
          position: "top-center",
        }
      );
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    // const today = new Date().toISOString().split("T")[0];
    // const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    // if (name === "date") {
    //   setLicense((prev) => ({
    //     ...prev,
    //     [name]: "",
    //   }));

    //   // Validate format
    //   if (!dateRegex.test(value)) {
    //     setDateError("Invalid date format (YYYY-MM-DD required)");
    //     return;
    //   }

    //   // Validate future date
    //   if (value > today) {
    //     setDateError("Date cannot be in the future");
    //     return;
    //   }
    // }
    setLicense((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   if (dateError) {
  //     const timeout = setTimeout(() => {
  //       setDateError("");
  //     }, 2000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [dateError]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setLicense((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  const handlePopoverToggle = (index) => {
    setOpenPopover((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Licenses</h3>
        </div>
        <div
          onClick={() => setOpenLicencesModal(true)}
          className="flex justify-center items-center gap-3 cursor-pointer"
        >
          <AddMore size={"size-8"} />
          <h3 className="text-xl">Add Licenses</h3>
        </div>
      </div>
      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col gap-6 pt-4">
          {loading ? (
            <>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className="border-b border-grey/30 last:border-0 pb-5 flex flex-col gap-2"
                >
                  <Skeleton key={index} className="h-4 w-40 rounded-full" />
                  <Skeleton className="h-3 w-24 rounded-full" />
                  <Skeleton className="h-3 w-32 rounded-full" />
                </div>
              ))}
            </>
          ) : (
            <>
              {licensesList?.length > 0 ? (
                <>
                  {licensesList?.map((license, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="vertical-center justify-between">
                          <p className="flex-1">{capitalize(license?.name)}</p>
                          <CustomPopover
                            open={openPopover[index]}
                            setOpen={() => handlePopoverToggle(index)}
                            placement="bottom-end"
                            trigger={
                              <div className="flex items-center gap-2">
                                <DotsIcon />
                              </div>
                            }
                          >
                            <div className="gap-3 w-32 flex flex-col items-center bg-white p-2 rounded-md">
                              <IconNavigation
                                icon={<EyeIcon width={22} height={22} />}
                                text="View"
                                onClick={() => {
                                  handlePopoverToggle(index);
                                  setOpenLicencesModal(true);
                                  setLicense({
                                    view: true,
                                    licenseName: license?.name,
                                    licenseNumber: license?.license_num,
                                    link: license?.license_url,
                                    date: license?.date,
                                    organization: license?.organization,
                                    proofUpload: license?.file_upload_url
                                      ? license?.file_upload_url
                                      : "",
                                  });
                                }}
                                row
                              />

                              <IconNavigation
                                icon={<EditIcon width={22} height={22} />}
                                text="Edit"
                                onClick={() => {
                                  handlePopoverToggle(index);
                                  setOpenLicencesModal(true);
                                  setLicense({
                                    edit: true,
                                    id: license?.id,
                                    licenseName: license?.name,
                                    licenseNumber: license?.license_num,
                                    link: license?.license_url,
                                    date: license?.date,
                                    organization: license?.organization,
                                  });
                                }}
                                row
                              />
                              <IconNavigation
                                icon={<DeleteIcon width={22} height={22} />}
                                text="Delete"
                                onClick={() => {
                                  handlePopoverToggle(index);
                                  setDeleteModal({
                                    open: true,
                                    data: {
                                      id: license?.id,
                                      name: "License",
                                      onClick: deleteLicenses,
                                    },
                                  });
                                }}
                                row
                              />
                            </div>
                          </CustomPopover>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{license?.organization}</p>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(license?.date)}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold mb-3">
                  No licenses found
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={
          license?.view
            ? "License Detail"
            : license?.edit
            ? "Edit License"
            : "Add License"
        }
        open={openLicencesModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form
          className="flex flex-col gap-5 mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            addLicense();
          }}
        >
          {license?.view ? (
            ""
          ) : (
            <>
              <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
                <p className="font-bold text-sm text-primary">
                  Professional Licenses
                </p>
              </div>

              <div className="vertical-center gap-3">
                <p className="font-nunito text-lg font-bold">
                  Name of Licensure{" "}
                </p>
                {loading ? <Spinner /> : null}
              </div>
            </>
          )}
          <TextInput
            label="License Name"
            name="licenseName"
            value={license.licenseName}
            onChange={handleFieldChange}
            required={!license?.view}
            disabled={license?.view}
          />

          <TextInput
            label="License Number"
            name="licenseNumber"
            value={license.licenseNumber}
            onChange={handleFieldChange}
            disabled={license?.view}
          />
          {license?.view ? (
            ""
          ) : (
            <TextInput
              label="Link"
              name="link"
              value={license.link}
              onChange={handleFieldChange}
              disabled={license?.view}
            />
          )}
          <TextInput
            label="Expiration Date"
            name="date"
            type="date"
            value={license.date}
            onChange={handleFieldChange}
            required={!license?.view}
            disabled={license?.view}
            error={dateError}
            errorMsg={dateError}
          />

          <TextInput
            label="Organization"
            name="organization"
            value={license.organization}
            onChange={handleFieldChange}
            required={!license?.view}
            disabled={license?.view}
          />

          {!license?.view && (
            <div className="mb-1">
              <label
                htmlFor="proofUpload"
                className="block text-[14px] font-medium text-mediumgray"
              >
                Proof of License
              </label>
              <input
                type="file"
                id="proofUpload"
                name="proofUpload"
                onChange={handleFileChange}
                className="mt-1 block w-fit text-sm text-mediumgray"
              />
            </div>
          )}

          {license?.view ? (
            <>
              {license?.proofUpload ? (
                <p
                  onClick={() =>
                    license?.proofUpload &&
                    window.open(license?.proofUpload, "_blank")
                  }
                  className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                >
                  <span className="pl-1">View file</span>
                </p>
              ) : (
                ""
              )}
              {license?.link ? (
                <p
                  onClick={() =>
                    license?.link && window.open(license?.link, "_blank")
                  }
                  className="cursor-pointer text-blue-600 hover:text-blue-800 underline flex items-center gap-2"
                >
                  <span className="pl-1">View link</span>
                </p>
              ) : (
                ""
              )}
            </>
          ) : (
            <AddMore
              text={license?.edit ? "Update License" : "Add License"}
              onClick={addLicense}
              disabled={
                loading ||
                isEmpty({
                  name: license?.licenseName,
                  date: license?.date,
                  organization: license?.organization,
                })
              }
            />
          )}
        </form>
      </CustomModal>
      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={loading}
      />
    </div>
  );
};
