import React from "react";
import { AvatarWithDetails } from "../AvatarWithDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChatIcon, CrossIcon, TrueIcon } from "../../../assets/icons";
import { updatePartnerStatusFunction } from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { CustomTooltip } from "../Tooltip";
import { Skeleton } from "../Skeleton";
import { showToast } from "../ShowToast";
import { capitalize } from "../../../utils/utils";

export const TopRatedCard = ({
  list,
  fetchTopRatedList,
  loading,
  matched,
  src,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(
      updatePartnerStatusFunction({
        id,
        status: status?.toUpperCase(),
      })
    );

    if (data.meta.requestStatus === "fulfilled") {
      fetchTopRatedList();
    }
    if (data?.meta?.requestStatus === "rejected") {
      console?.log(data?.payload?.message[0]);
      showToast(
        `${
          data?.payload
            ? data?.payload?.message[0]?.error
            : "Something went wrong"
        }`,
        "error",
        {
          position: "top-center",
        }
      );
      return;
    }
  };

  return (
    <div className={`flex flex-col `}>
      {loading ? (
        Array.from({ length: 15 }, (_, index) => (
          <Skeleton key={index} className="h-10 my-2 rounded-md" />
        ))
      ) : (
        <>
          {list?.map((item) => {
            const partner = matched ? item?.matched_user : item;

            return (
              <div
                key={item.id}
                className="py-[4px] flex ml-0 justify-between items-center rounded-lg bg-white transition duration-300 ease-in-out transform hover:shadow-lg cursor-pointer"
                onClick={() =>
                  navigate(`/partners/partner-detail/${partner.id}`)
                }
              >
                <AvatarWithDetails
                  src={item?.profile_image || item?.matched_user?.profile_image}
                  name={partner?.first_name || item?.matched_user?.first_name}
                  designation={
                    item?.profession || item?.matched_user?.profession
                  }
                  sx={"!flex !flex-row !justify-center !items-center"}
                  descriptioSx={"pl-2"}
                  avatarSx={"!w-[30px] !h-[30px] "}
                />

                <div className="flex gap-2">
                  {matched ? (
                    <CustomTooltip content={"Chat"}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/chat`, {
                            state: {
                              user_id: partner?.id,
                              completeItem: {
                                avatar: partner?.profile_image || "",
                                channel_id: null,
                                chatType: "individual",
                                id: partner?.id,
                                name:
                                  capitalize(partner?.first_name) +
                                    " " +
                                    capitalize(partner?.last_name) || "",
                                online: false,
                                selected: false,
                              },
                            },
                          });
                        }}
                        className="p-0.5 rounded-lg border-[1.5px] border-lightgray"
                      >
                        <ChatIcon color="gray" width={15} height={15} />
                      </div>
                    </CustomTooltip>
                  ) : (
                    <>
                      <CustomTooltip content={"Match"}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            updatePartnerStatus("match", item?.id);
                          }}
                          className="p-0.5 rounded-lg border-[1.5px] border-lightgray"
                        >
                          <TrueIcon color="gray" width={15} height={15} />
                        </div>
                      </CustomTooltip>

                      <CustomTooltip content={"Skip"}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            updatePartnerStatus("deprioritize", partner.id);
                          }}
                          className="p-0.5 rounded-lg border-[1.5px] border-lightgray"
                        >
                          <CrossIcon color="gray" width={15} height={15} />
                        </div>
                      </CustomTooltip>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
