// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for portfolio images
const initialState = {
  portfolioImages: [],
  loading: false,
  error: null,
};

// Upload Portfolio Images API Function
export const uploadPortfolioImagesFunction = createAsyncThunk(
  'portfolio/uploadImages',
  async (images, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append('images', image); // Append each image to the form data
      });

      const { data } = await axios.post(endPoints.PortfolioImages, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type for file upload
        },
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Get Portfolio Images API Function
export const getPortfolioImagesFunction = createAsyncThunk(
  'portfolio/getImages',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.PortfolioImages);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Delete Portfolio Image API Function
export const deletePortfolioImageFunction = createAsyncThunk(
  'portfolio/deleteImage',
  async (imageId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `${endPoints.PortfolioImages}${imageId}/`
      );
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const portfolioImagesSlice = createSlice({
  name: 'portfolio/images',
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },

  extraReducers: (builder) => {
    // Upload Portfolio Images Reducers
    builder
      .addCase(uploadPortfolioImagesFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadPortfolioImagesFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.portfolioImages = action.payload; // Update the state with the uploaded images
        state.error = null;
      })
      .addCase(uploadPortfolioImagesFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Get Portfolio Images Reducers
    builder
      .addCase(getPortfolioImagesFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPortfolioImagesFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.portfolioImages = action.payload; // Update the state with the fetched images
        state.error = null;
      })
      .addCase(getPortfolioImagesFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Delete Portfolio Image Reducers
    builder
      .addCase(deletePortfolioImageFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePortfolioImageFunction.fulfilled, (state, action) => {
        state.loading = false;
        // Remove the deleted image from the state
        state.portfolioImages = state.portfolioImages.filter(
          (image) => image.id !== action.payload.id
        );
        state.error = null;
      })
      .addCase(deletePortfolioImageFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default portfolioImagesSlice.reducer;