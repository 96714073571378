/* Library imports */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  TextInput,
  AddMore,
  CustomModal,
  showToast,
  Skeleton,
  CustomCheckbox,
  CustomPopover,
  IconNavigation,
  Spinner,
} from "../../../../components/shared";
import {
  deleteEducationsFunction,
  getEducationsFunction,
  updateEducationsFunction,
} from "../../../../redux/reducers/userReducers/educationReducer/educationReducer";
import { isEmpty } from "../../../../utils/validations";
import {
  DeleteIcon,
  DotsIcon,
  EditIcon,
  EyeIcon,
  LongArrow,
} from "../../../../assets/icons";
import { formatDate } from "../../../../utils/utils";
import { ProfileDeleteModal } from "../DeleteModal";

// Education Initial Data
const initialEducationData = {
  institute: "",
  major: "",
  minor: "",
  honors: "",
  startDate: "",
  endDate: "",
  isOngoing: false,
};

export const Education = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { educations: educationsListing, loading: educationsLoading } =
    useSelector((state) => state?.educations);
  const [openEducationModal, setOpenEducationModal] = useState(false);
  const [dateError, setDateError] = useState({ startDate: "", endDate: "" });
  const [openPopover, setOpenPopover] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [education, setEducation] = useState(initialEducationData);
  const getEducationListing = async () => {
    await dispatch(getEducationsFunction());
  };
  useLayoutEffect(() => {
    getEducationListing();
  }, [dispatch]);

  const handleEducationChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue =
      name === "startDate" || name === "endDate" ? value.trim() : value;
    const today = new Date().toISOString().split("T")[0];
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    setDateError((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (trimmedValue === "") {
      setEducation((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      return;
    }

    if (name === "startDate" || name === "endDate") {
      // Validate date format
      if (!dateRegex.test(trimmedValue)) {
        setDateError((prev) => ({
          ...prev,
          [name]: "Invalid date format (YYYY-MM-DD required)",
        }));
        return;
      }

      // Validate start date is not in the future
      if (name === "startDate" && trimmedValue > today) {
        setDateError((prev) => ({
          ...prev,
          startDate: "Start date cannot be in the future",
        }));
        return;
      }
    }

    // Update education state for all fields
    setEducation((prevState) => ({
      ...prevState,
      [name]: trimmedValue,
    }));
  };

  // Add or Edit Education
  const addEducation = async (event) => {
    event.preventDefault();
    const today = new Date().toISOString().split("T")[0];
    let errorMessage = "";

    // Validate endDate
    if (education?.endDate) {
      if (new Date(education.endDate) > new Date(today)) {
        errorMessage = "End date cannot be in the future.";
        education.endDate = "";
      } else if (new Date(education.endDate) < new Date(education.startDate)) {
        errorMessage = "End date must be after start date.";
        education.endDate = "";
      }
    }

    // If there's an error, set the error state and stop the submission
    if (errorMessage) {
      setDateError((prev) => ({
        ...prev,
        endDate: errorMessage,
      }));

      return;
    }
    // Check for date errors before submission
    if (dateError.startDate || dateError.endDate) {
      showToast("Please fix date errors before submitting", "error", {
        position: "top-center",
      });
      return;
    }

    const formData = {
      institution: education?.institute,
      major: education?.major,
      minor: education.minor,
      honors: education.honors,
      start_date: education.startDate,
      end_date: education.isOngoing ? null : education.endDate,
      is_ongoing: education.isOngoing,
    };

    const id = education?.id;
    try {
      const data = await dispatch(updateEducationsFunction({ formData, id }));

      // Check if the request was fulfilled or rejected
      if (data?.meta?.requestStatus === "fulfilled") {
        setOpenEducationModal(false);
        showToast(
          education?.id
            ? "Education updated successfully"
            : "Education added successfully",
          "success",
          {
            position: "top-center",
          }
        );
        getEducationListing(); // Fetch updated educations list
        setEducation(initialEducationData); // Reset education state
      } else {
        const errorMessages = data?.payload || "Something went wrong";
        showToast(errorMessages, "error", { position: "top-center" });
      }
    } catch (error) {
      showToast("An unexpected error occurred", "error", {
        position: "top-center",
      });
    }
  };

  const deleteEducation = async (id) => {
    const data = await dispatch(
      deleteEducationsFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Education Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getEducationListing();
    } else if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join()).join("\n"),
        "error",
        { position: "top-center" }
      );
    }
  };

  // Handle checkbox
  const handleCheckbox = (checked) => {
    if (checked === true) {
      setEducation((prev) => ({
        ...prev,
        endDate: "",
      }));
    }
    setEducation((prev) => ({
      ...prev,
      isOngoing: checked,
    }));
  };

  const handlePopoverToggle = (index) => {
    setOpenPopover((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (dateError) {
      const timeout = setTimeout(() => {
        setDateError({
          experience: { startDate: "", endDate: "" },
          project: { startDate: "", endDate: "" },
        });
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [dateError]);
  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Education</h3>
        </div>
        <div
          onClick={() => setOpenEducationModal(true)}
          className="flex justify-center items-center gap-3 cursor-pointer"
        >
          <AddMore size={"size-7"} />
          <h3 className="text-xl">Add Education</h3>
        </div>
      </div>

      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col gap-6 mt-4">
          {educationsLoading ? (
            <>
              {Array.from({ length: 2 }, (_, index) => {
                return (
                  <div
                    key={index}
                    className="border-b border-grey/30 last:border-0 pb-5"
                  >
                    <div className="font-nunito vertical-center justify-between">
                      <Skeleton className="h-5 w-52 rounded-full" />
                    </div>
                    <div className="vertical-center gap-2 text-sm text-grey mt-2">
                      <Skeleton className="h-4 w-12 rounded-full" />
                      <Skeleton className="h-4 w-12 rounded-full" />
                    </div>

                    <div className="vertical-center gap-2 text-sm text-grey mt-2">
                      <Skeleton className="h-4 w-16 rounded-full" />
                      <Skeleton className="h-4 w-16 rounded-full" />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {educationsListing?.length > 0 ? (
                <>
                  {educationsListing?.map((education, index) => {
                    // Major and Minor
                    const majorMinor = [];
                    majorMinor.push(education?.major);
                    majorMinor.push(education?.minor);

                    // Duration
                    const duration = [];
                    duration.push(formatDate(education?.start_date));
                    duration.push(formatDate(education?.end_date));

                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="font-nunito vertical-center justify-between">
                          <h2 className="text-lg">{education?.institution}</h2>
                          <div className="center gap-3">
                            <CustomPopover
                              open={openPopover[index]}
                              setOpen={() => handlePopoverToggle(index)}
                              placement="bottom-end"
                              trigger={
                                <div className="flex items-center gap-2">
                                  <DotsIcon />
                                </div>
                              }
                            >
                              <div className="gap-3 w-32 flex flex-col items-center bg-white p-2 rounded-md">
                                {/* View Icon */}
                                <IconNavigation
                                  icon={<EyeIcon width={22} height={22} />}
                                  text="View"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setOpenEducationModal(true);
                                    setEducation({
                                      view: true,
                                      institute: education?.institution,
                                      major: education?.major,
                                      minor: education?.minor,
                                      honors: education?.honors,
                                      startDate: education?.start_date,
                                      endDate: education?.end_date,
                                      isOngoing: education?.is_ongoing,
                                    });
                                  }}
                                  row
                                />
                                <IconNavigation
                                  icon={<EditIcon width={22} height={22} />}
                                  text="Edit"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setOpenEducationModal(true);
                                    setEducation({
                                      edit: true,
                                      id: education?.id,
                                      institute: education?.institution,
                                      major: education?.major,
                                      minor: education?.minor,
                                      honors: education?.honors,
                                      startDate: education?.start_date,
                                      endDate: education?.end_date,
                                      isOngoing: education?.is_ongoing,
                                    });
                                  }}
                                  row
                                />
                                {/* Delete Icon */}
                                <IconNavigation
                                  icon={<DeleteIcon width={22} height={22} />}
                                  text="Delete"
                                  onClick={() => {
                                    handlePopoverToggle(index);
                                    setDeleteModal({
                                      open: true,
                                      data: {
                                        id: education?.id,
                                        name: "Education",
                                        onClick: deleteEducation,
                                      },
                                    });
                                  }}
                                  row
                                />
                              </div>
                            </CustomPopover>
                          </div>
                        </div>
                        <div className="vertical-center text-sm text-grey">
                          <p>{majorMinor?.filter(Boolean).join(", ")}</p>
                        </div>

                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{duration?.filter(Boolean).join(" - ")}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold">No Education Added</p>
              )}
            </>
          )}
        </div>
      </div>

      {/* Add/Edit Education Modal */}
      <CustomModal
        title={
          education?.view
            ? "Education Detail"
            : education?.id
            ? "Edit Education"
            : "Add Education"
        }
        open={openEducationModal}
        close={() => {
          setEducation(initialEducationData);
          setOpenEducationModal(false);
        }}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addEducation} className="space-y-4">
          <div className="flex flex-col gap-5">
            {education?.view ? (
              ""
            ) : (
              <>
                <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
                  <p className="font-bold text-sm text-primary">
                    Academic History
                  </p>
                </div>

                <div className="vertical-center gap-2">
                  <h2 className="text-lg font-bold text-gray-800">Education</h2>
                  {educationsLoading ? <Spinner /> : null}
                </div>
              </>
            )}
            <div className={`space-y-4`}>
              <TextInput
                label="Name of Institute"
                name="institute"
                value={education?.institute}
                onChange={handleEducationChange}
                required={!education?.view}
                disabled={education?.view}
              />

              <TextInput
                label="Major"
                name="major"
                value={education.major}
                onChange={handleEducationChange}
                required={!education?.view}
                disabled={education?.view}
              />
              <TextInput
                label="Minor"
                name="minor"
                value={education.minor}
                onChange={handleEducationChange}
                disabled={education?.view}
              />
              <TextInput
                label="Honors"
                name="honors"
                value={education.honors}
                onChange={handleEducationChange}
                disabled={education?.view}
              />
              <TextInput
                label="Start Date"
                name="startDate"
                type="date"
                value={education?.startDate}
                onChange={handleEducationChange}
                max={new Date().toISOString().split("T")[0]}
                required={!education?.view}
                disabled={education?.view}
                error={dateError.startDate}
                errorMsg={dateError.startDate}
              />

              {education?.view ? (
                <TextInput
                  label="End Date"
                  name="endDate"
                  type="text"
                  value={
                    education.isOngoing ? "Present" : education?.endDate || ""
                  }
                  onChange={handleEducationChange}
                  disabled={true}
                />
              ) : (
                <TextInput
                  label="End Date"
                  name="endDate"
                  type="date"
                  value={education.endDate}
                  onChange={handleEducationChange}
                  disabled={education?.isOngoing === true}
                  min={education?.startDate}
                  // max={new Date().toISOString().split("T")[0]}
                  required={!education?.isOngoing}
                  error={dateError.endDate}
                  errorMsg={dateError.endDate}
                />
              )}
            </div>
            {education?.view ? (
              ""
            ) : (
              <div className="pl-1">
                <CustomCheckbox
                  label="Ongoing"
                  checked={education.isOngoing}
                  onChange={handleCheckbox}
                />
              </div>
            )}
          </div>
          {education?.view ? (
            ""
          ) : (
            <AddMore
              text={education?.id ? "Edit Education" : "Add Education"}
              onClick={addEducation}
              disabled={
                educationsLoading ||
                isEmpty({
                  institute: education?.institute,
                  major: education?.major,
                  startDate: education?.startDate,
                  ...(!education?.isOngoing && {
                    endDate: education?.endDate,
                  }),
                }) ||
                dateError.startDate ||
                dateError.endDate
              }
            />
          )}
        </form>
      </CustomModal>
      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={educationsLoading}
      />
    </div>
  );
};
