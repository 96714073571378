import React from "react";

// Local Imports
import {
  SignupInterestCheckbox,
  TextInput,
} from "../../../../components/shared";

const RolesInterests = ({ formData, setFormData, onSubmit }) => {
  // Handle About Change
  const handleAboutChange = (name) => {
    const updatedRoles = formData?.roles?.map((item) => {
      if (item?.name === name) {
        return {
          ...item,
          value: !item?.value,
        };
      }
      return item; 
    });
    
    setFormData((prev) => ({
      ...prev,
      rolesInterests: {
        ...prev?.rolesInterests,
        roles: updatedRoles,
      },
    }));
    
  };

  // Handle Interests Change
  const handleInterestsChange = (name) => {
    const updatedInterests = formData?.interests?.map((item) => {
      if (item?.name === name) {
        return {
          ...item,
          value: !item?.value,
        };
      }
      return item;
    });
    setFormData((prev) => ({
      ...prev,
      rolesInterests: {
        ...prev?.rolesInterests,
        interests: updatedInterests,
      },
    }));
  };

  return (
    <>
      {/* Headings */}
      <div className="font-nunito mb-5">
        <p className="text-xl font-bold">Tell Us About Your Goals</p>
      </div>

      {/* About*/}
      <form className="flex flex-col gap-5 mb-2" onSubmit={onSubmit}>
        <div className="mb-3">
          <p className="font-semibold text-lg">I’m primarily a(n):</p>

          <div className="flex gap-3 flex-wrap mt-3">
            {formData?.roles?.map((item) => {
              return (
                <SignupInterestCheckbox
                  key={item?.id}
                  name={item?.name}
                  value={item?.value}
                  onChange={() => handleAboutChange(item?.name)}
                >
                  {item?.label}
                </SignupInterestCheckbox>
              );
            })}
          </div>
        </div>
        <TextInput
          label="Other"
          type="text"
          name="other"
          value={formData?.other}
          onChange={(event) =>
            setFormData((prev) => ({
              ...prev,
              rolesInterests: {
                ...prev?.rolesInterests,
                other: event.target.value,
              },
            }))
          }
        />

        <div className="mt-2">
          <p className="font-semibold text-lg">I am interested in</p>
          <div className="flex gap-3 flex-wrap mt-3">
            {formData?.interests?.map((item) => {
              return (
                <SignupInterestCheckbox
                  key={item?.id}
                  name={item?.name}
                  value={item?.value}
                  onChange={() => handleInterestsChange(item?.name)}
                >
                  {item?.label}
                </SignupInterestCheckbox>
              );
            })}
          </div>
        </div>
        <TextInput
          label="Other"
          type="text"
          name="user_interest"
          value={formData?.user_interest}
          onChange={(event) =>
            setFormData((prev) => ({
              ...prev,
              rolesInterests: {
                ...prev?.rolesInterests,
                user_interest: event.target.value,
              },
            }))
          }
        />
      </form>
    </>
  );
};

export default RolesInterests;
