import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local Imports
import {
  CustomModal,
  CustomTooltip,
  SelectDropdown,
  showToast,
  Skeleton,
} from "../../../../components/shared";
import { UnverifiedIcon, VerifiedIcon } from "../../../../assets/icons";
import { userVerificationFunction } from "../../../../redux/reducers/adminReducers/userVerificationReducer";
import { verificationOptions } from "../../../../utils/mockupData";
import { formatDate } from "../../../../utils/utils";

export const Education = ({ loading, profileData, getUserDetails }) => {
  const { education } = profileData;
  const dispatch = useDispatch();
  const { loading: verificationLoading } = useSelector(
    (state) => state?.verification
  );
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null,
  });
  const [verification, setVerification] = useState("");

  const verifyEducation = async () => {
    const data = await dispatch(
      userVerificationFunction({
        userId: profileData?.id,
        id: openModal?.data?.id,
        section: "education",
        verification: verification,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      console.log("error", data);

      const errorMessages = Array.isArray(data?.payload)
        ? data.payload.map((err) => Object.values(err).join(" ")).join(", ")
        : "Something went wrong";

      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenModal({ open: false, data: null });
      getUserDetails();
    }
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 mt-6 select-none">
            {education?.map((edu, index) => {
              const majorMinor = [edu?.major, edu?.minor]
                .filter(Boolean)
                .join(", ");
              const duration = [
                formatDate(edu?.start_date),
                formatDate(edu?.end_date),
              ]
                .filter(Boolean)
                .join(" - ");

              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 w-full min-h-12 select-none hover:shadow-md"
                  // onClick={() => {
                  //   setVerification(edu?.verification);
                  //   setOpenModal({ open: true, data: edu });
                  // }}
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold truncate">
                      {edu?.institution}
                    </p>
                    {/* <CustomTooltip
                      content={
                        edu?.verification === "VERIFIED"
                          ? "Verified"
                          : "Unverified. Click to verify"
                      }
                    >
                      {edu?.verification === "VERIFIED" ? (
                        <VerifiedIcon className="text-success size-5" />
                      ) : (
                        <UnverifiedIcon className="text-gold size-5" />
                      )}
                    </CustomTooltip> */}
                  </div>
                  <div className="vertical-center text-sm text-grey">
                    <p>{majorMinor}</p>
                  </div>
                  <div className="vertical-center gap-2 text-sm text-grey">
                    <p>{duration}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <CustomModal
        title={"Verify Education"}
        open={openModal?.open}
        close={() => setOpenModal({ open: false, data: null })}
        primaryButtonClick={verifyEducation}
        secondaryButtonClick={() => setOpenModal({ open: false, data: null })}
        primaryButtonText={"Update"}
        primaryButtonLoading={verificationLoading}
      >
        <div className="font-nunito pb-3">
          <p className="text-sm font-semibold mb-4">
            Please update the status of this user's education
          </p>
          <SelectDropdown
            options={verificationOptions}
            loading={false}
            label="Select an option"
            name="verification"
            value={verification}
            onChange={(e) => setVerification(e.target.value)}
          />
        </div>
      </CustomModal>
    </>
  );
};
