// Library imports
import React, { useEffect, useState } from "react";
import { Avatar, Badge } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Icons imports
import {
  ProfileIcon,
  DotsIcon,
  BellIcon,
  SettingIcon,
} from "../../../assets/icons";

// Local imports
import { assets } from "../../../assets/images";
import { IconNavigation } from "../IconNavigation";
import { CustomPopover } from "../Popover";
import { userSideMenuRoutes } from "../../../routes/config";
import { capitalize, checkRoute, logoutFn } from "../../../utils/utils";
import { CustomModal } from "../Modal";
import { useSocket } from "../../../redux/socket/socket";
import { CustomTooltip } from "../Tooltip";

export const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.user);
  const { profileData: profileDetails } = useSelector(
    (state) => state.profileDetails
  );
  const [openModal, setOpenModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const { channels } = useSocket();

  const [isMd, setIsMd] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMd(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="bg-white min-h-[65px] w-full border-b border-mediumgrey/50">
        <nav className="max-w-[1280px] mx-auto px-6 py-2  pt-3.5 md:pt-2 vertical-center justify-between">
          <div
            className="font-nunito flex items-baseline justify-center cursor-pointer"
            onClick={() => navigate("/home")}
          >
            <img src={assets.logo} alt="logo" className="size-9" />
          </div>

          {/* Navigation */}
          <div
            className={`flex gap-8 ${
              isMd ? "justify-end ml-4" : "justify-center"
            } items-center text-grey`}
          >
            {userSideMenuRoutes
              ?.filter((_, index) => isMd || index > 1) // Show only first two items before md
              .map((menu, index) => (
                <IconNavigation
                  active={checkRoute(menu?.path)}
                  key={index}
                  icon={menu?.icon}
                  text={menu?.text}
                  onClick={() => navigate(menu?.path)}
                />
              ))}
          </div>

          {/* Notification */}

          <div className="vertical-center gap-5 md:gap-8">
            <CustomTooltip
              content={
                "This figure represents the total number of partner requests you’ve received so far."
              }
            >
              <Badge
                content={
                  channels?.length > 0 ? channels?.[0]?.requested_partners : ""
                }
                size="sm"
                color="danger"
                shape="circle"
                placement="top-right"
                isInvisible={false}
                classNames={{
                  badge:
                    "-translate-y-[3px] translate-x-1 text-white text-[10px]",
                }}
                showOutline={false}
              >
                <BellIcon className="cursor-pointer size-5 md:size-6" />
              </Badge>
            </CustomTooltip>

            {/* User Info */}
            <CustomPopover
              open={openPopover}
              setOpen={setOpenPopover}
              placement="bottom-end"
              trigger={
                <div>
                  <div className="hidden md:vertical-center gap-2">
                    <Avatar
                      src={
                        profileDetails?.profile_image?.startsWith("http")
                          ? profileDetails.profile_image
                          : undefined
                      }
                      classNames={{
                        base: "bg-mediumgrey/30",
                        icon: "text-black/60",
                      }}
                    />
                  </div>
                  <div className="flex md:hidden items-center gap-2">
                    <DotsIcon />
                  </div>
                </div>
              }
            >
              <div className="gap-3 w-48 flex flex-col items-center bg-white p-2 rounded-md">
                <div className="flex flex-col gap-3 w-full border-b border-gray-300 pb-2">
                  <div className="vertical-center gap-2">
                    <Avatar
                      src={
                        profileDetails?.profile_image?.startsWith("http")
                          ? profileDetails.profile_image
                          : undefined
                      }
                      classNames={{
                        base: "bg-mediumgrey/30",
                        icon: "text-black/60",
                      }}
                    />
                    <div className="font-nunito">
                      <p className="font-semibold text-sm">
                        {user
                          ? capitalize(user?.first_name) +
                            " " +
                            capitalize(user?.last_name)
                          : "John Doe"}
                      </p>
                      <p className="font-semibold text-[13px] text-darkgrey">
                        {user ? user?.role : "--"}
                      </p>
                    </div>
                  </div>

                  <IconNavigation
                    active={checkRoute("/profile/profile-details")}
                    icon={<ProfileIcon width={22} height={22} />}
                    text="Profile"
                    onClick={() => {
                      setOpenPopover(false);
                      navigate("/profile/profile-details");
                    }}
                    row
                  />

                  <IconNavigation
                    active={checkRoute("/account-settings")}
                    icon={<SettingIcon width={22} height={22} />}
                    text="Account Settings"
                    onClick={() => {
                      setOpenPopover(false);
                      navigate("/account-settings");
                    }}
                    row
                  />
                </div>

                <div className="md:hidden flex flex-col gap-3 w-full border-b border-gray-300 pb-2">
                  {userSideMenuRoutes?.map((menu, index) => {
                    return (
                      <IconNavigation
                        active={checkRoute(menu?.path)}
                        key={index}
                        icon={menu?.icon}
                        text={menu?.text}
                        onClick={() => {
                          setOpenPopover(false);
                          navigate(menu?.path);
                        }}
                        row
                      />
                    );
                  })}
                </div>

                <p
                  className="font-nunito font-semibold text-sm cursor-pointer"
                  onClick={() => {
                    setOpenPopover(false);
                    setOpenModal(true);
                  }}
                >
                  Log Out
                </p>
              </div>
            </CustomPopover>
          </div>
        </nav>
      </div>

      <div className="bg-bggrey h-[calc(100vh-65px)] overflow-auto">
        <div className="max-w-[1280px] mx-auto px-2 smd:px-6">{children}</div>
      </div>

      <CustomModal
        title={"Logout"}
        open={openModal}
        close={() => setOpenModal(false)}
        primaryButtonClick={() => {
          logoutFn();
          navigate("/login");
        }}
        secondaryButtonClick={() => setOpenModal(false)}
        primaryButtonText={"Logout"}
      >
        <div className="font-nunito pb-3 flex justify-between items-center">
          <p className="font-bold text-sm">Are you sure you want to logout?</p>
        </div>
      </CustomModal>
    </div>
  );
};
