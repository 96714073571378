/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  CustomModal,
  CustomPopover,
  CustomTable,
  showToast,
  Spinner,
  TextInput,
} from "../../../components/shared";
import { usersColumnData } from "../../../utils/mockupData";
import { DeleteIcon, DotsIcon, ProfileIcon } from "../../../assets/icons";
import {
  deleteUserFunction,
  updateUserStatusFunction,
  usersListingFunction,
} from "../../../redux/reducers/adminReducers/usersReducer";
import { ActiveIcon } from "../../../assets/icons/ActiveStatus";
import { InActiveIcon } from "../../../assets/icons/inActiveStatus";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usersListingLoading, userDetailLoading, updateUserStatusLoading } =
    useSelector((state) => state?.usersListing);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null,
  });
  const [formData, setFormData] = useState({
    search: "",
  });
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(formData.search);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [formData.search]);

  useEffect(() => {
    if (debouncedSearch) {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
        user.email.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [debouncedSearch, users]);
  

  const handleUpdateUserStatus = async (userId, isActive) => {
    if (!userId) {
      showToast("User ID is required", "error", { position: "top-center" });
      return;
    }

    const data = await dispatch(
      updateUserStatusFunction({ userId, is_active: isActive })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessage = data?.payload || "Something went wrong";
      showToast(errorMessage, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenPopover(null);
      showToast(
        `User status updated to ${isActive ? "Active" : "Inactive"}`,
        "success",
        {
          position: "top-center",
        }
      );
      getUsers();
    }
  };

  const popoverData = [
    {
      name: "Profile",
      icon: <ProfileIcon width={20} height={20} />,
      onClick: (row) => {
        setOpenPopover(null);
        navigate(`/users/user-detail/${row?.id}`);
      },
    },
    {
      name: "Delete",
      icon: <DeleteIcon width={19} height={19} />,
      onClick: (row) => {
        setOpenPopover(null);
        setOpenModal({ open: true, data: row });
      },
    },
    {
      name: rowData?.status ? "Inactive" : "Active",
      icon: updateUserStatusLoading ? (
        <Spinner className={"text-secondary size-4"} />
      ) : rowData?.status ? (
        <InActiveIcon width={17} height={17} color={"red"} />
      ) : (
        <ActiveIcon width={19} height={19} color={"green"} />
      ),
      onClick: (row) => {
        handleUpdateUserStatus(row?.id, row?.status ? false : true);
      },
    },
  ];

  const getUsers = async () => {
    const response = await dispatch(usersListingFunction());
    const data = response?.payload?.map((data) => {
      return {
        id: data?.id,
        name: data?.full_name,
        email: data?.email,
        phone: data?.phone_no,
        status: data?.is_active,
      };
    });
    setUsers(data);
    setFilteredUsers(data);
  };

  useLayoutEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (id) => {
    const data = await dispatch(deleteUserFunction({ id: id }));
    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }
    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenModal({ open: false, data: null });
      showToast("User Deleted Successfully", "success", {
        position: "top-center",
      });
      getUsers();
    }
  };

  return (
    <div className="py-3 px-3">
      <div className="flex justify-end">
        <div className={"py-3 mb-1 mx-auto sm:mx-0 w-[300px]"}>
          <TextInput
            label="Search"
            type="text"
            name="search"
            value={formData?.search}
            onChange={onChange}
          />
        </div>
      </div>
      <CustomTable
        rowsData={filteredUsers?.length > 0 ? filteredUsers : []}
        columns={usersColumnData}
        loading={usersListingLoading}
        dataProviders={[
          {
            columnName: "actions",
            component: ({ row }) => {
              return (
                <div aria-hidden="false" className="vertical-center gap-2">
                  <CustomPopover
                    open={openPopover === row?.id}
                    setOpen={(isOpen) => {
                      setOpenPopover(isOpen ? row?.id : null);
                      setRowData(isOpen ? row : null);
                    }}
                    trigger={
                      <div>
                        <DotsIcon />
                      </div>
                    }
                  >
                    <div className="bg-white py-2 px-2 w-32 rounded-md font-nunito text-sm flex flex-col gap-2">
                      {popoverData?.map((data, index) => {
                        const { name, icon, onClick } = data;
                        return (
                          <div
                            key={index}
                            className="vertical-center gap-2 cursor-pointer"
                            onClick={() => onClick(row)}
                          >
                            {icon}
                            <p>{name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </CustomPopover>
                </div>
              );
            },
          },
          {
            columnName: "status",
            component: ({ row }) => {
              return (
                <div
                  className={`center rounded-full gap-2 w-fit px-3 select-none ${
                    row?.status ? "bg-success/80" : "bg-danger/80"
                  }`}
                >
                  <p className="text-sm text-white">
                    {row?.status ? "Active" : "Inactive"}
                  </p>
                </div>
              );
            },
          },
        ]}
      />
      <CustomModal
        title={"Delete User"}
        open={openModal?.open}
        close={() => setOpenModal({ open: false, data: null })}
        primaryButtonText="Delete"
        primaryButtonColor="danger"
        primaryButtonClick={() => deleteUser(openModal?.data?.id)}
        primaryButtonLoading={userDetailLoading}
      >
        <p className="text-sm">
          Are you sure you want to delete{" "}
          <span className="font-semibold">{openModal?.data?.name}</span>?
        </p>
      </CustomModal>
    </div>
  );
};

export default Users;