/* Library imports */
import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Icons Imports
import {
  BagIcon,
  BirthdayIcon,
  ChatIcon,
  CrossIcon,
  LocationIcon,
  LongArrow,
  MailIcon,
  PhoneIcon,
  TrueIcon,
} from "../../../../assets/icons";

// Local Imports
import {
  Carousel,
  CustomModal,
  DetailModal,
  Divider,
  PartnersProfileButton,
  showToast,
  Skeleton,
  Spinner,
} from "../../../../components/shared";
import { Avatar } from "@nextui-org/react";
import { capitalize, formatDate } from "../../../../utils/utils";
import { assets } from "../../../../assets/images";
import { Experiences } from "./Experiences";
import { Projects } from "./Projects";
import { Certifications } from "./Certifications";
import Licences from "./Licences";
import { Skills } from "./Skills";
import { Recommendations } from "./Recommentdations";
import {
  getPartnersListingFunction,
  partnerDetailsFunction,
  updatePartnerStatusFunction,
} from "../../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { Education } from "./Education";
import { useSocket } from "../../../../redux/socket/socket";

const PartnersDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sendCommand } = useSocket();
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [status, setStatus] = useState(null);

  // State for managing modals
  const [modals, setModals] = useState({
    experience: { open: false, data: null },
    projects: { open: false, data: null },
    education: { open: false, data: null },
    certification: { open: false, data: null },
    license: { open: false, data: null },
  });

  const { sPartnerDetails, sPartnerDetailsLoading, partnerStatusLoading } =
    useSelector((state) => state?.partners);
  console?.log("partnerStatusLoading", partnerStatusLoading);
  useLayoutEffect(() => {
    getPartnerDetails();
  }, []);

  // Handle Partner Details Modal
  const handleCloseModal = () => {
    setOpenPartnerModal(false);
  };

  // Handle Status Functions
  const handleStatusChange = (status, id) => {
    setStatus(status);
    updatePartnerStatus(status, id);
  };

  // Update Partner Status
  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(
      updatePartnerStatusFunction({
        id: id,
        status: status?.toUpperCase(),
      })
    );

    if (data?.meta?.requestStatus === "fulfilled") {
      sendCommand("fetch_channels");
      handleCloseModal();
      // handleClearFilter?.();
      // navigate(-1);
      getPartnerDetails();
      await dispatch(getPartnersListingFunction());
    }
  };

  // Get partnerDetails Details
  const getPartnerDetails = async () => {
    const data = await dispatch(partnerDetailsFunction({ id: id }));

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }
  };

  // Open specific modal with data
  const handViewOpenModal = (type, data = null) => {
    setModals((prev) => ({
      ...prev,
      [type]: { open: true, data },
    }));
  };

  // Close all modals
  const handleNewCloseModal = () => {
    setModals({
      experience: { open: false, data: null },
      projects: { open: false, data: null },
      education: { open: false, data: null },
      certification: { open: false, data: null },
      license: { open: false, data: null },
    });
  };

  return (
    <>
      {sPartnerDetailsLoading ? (
        <div className="relative pb-10 mx-3 mt-3 font-nunito border border-grey/30 rounded-2xl overflow-hidden">
          <div className="z-10 relative pl-6 py-6 pb-[4.5rem]">
            <h3 className="text-xl">Profile</h3>
          </div>
          <img
            src={assets?.coverbg}
            loading="lazy"
            alt="cover-image"
            className="w-full absolute top-0 left-0 z-0 h-48 object-cover rounded-t-xl"
          />
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-baseline justify-center sm:justify-between relative z-10 mb-5">
              <div className="vertical-center justify-center border-4 border-white bg-white rounded-full z-10">
                <Skeleton className="size-32 rounded-full" />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div className="flex flex-col items-center sm:items-start gap-2">
                <Skeleton className="w-40 h-6 rounded-full" />
                <Skeleton className=" w-[83px] h-[35px] rounded-full" />
              </div>
              <div className="sm:hidden center gap-2 font-semibold cursor-pointer hover:text-secondary transition">
                <Skeleton className="w-32 h-6 rounded-full" />
              </div>

              <Skeleton className="px-8 py-4 rounded-md mt-10  " />
            </div>

            <div className="smd:grid grid-cols-12 gap-1">
              <div className="flex flex-col gap-4 w-full col-start-1 col-span-6 max-smd:mt-10">
                <Skeleton className="w-60 h-3 rounded-full" />
                <Skeleton className="w-60 h-3 rounded-full" />
                <Skeleton className="w-60 h-3 rounded-full" />
              </div>

              <div className="w-full max-smd:mt-10 col-span-7 smd:col-span-5 md:col-span-4 lg:col-span-3 col-start-7 smd:col-start-8 md:col-start-9 lg:col-start-10">
                <div>
                  <p className="text-md font-semibold">Role</p>
                  <Divider />
                  <div className="mt-3 flex flex-wrap items-center gap-2">
                    {Array.from({ length: 3 }, (_, index) => (
                      <Skeleton
                        key={index}
                        className="flex rounded-full w-24 h-8"
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-md font-semibold">Interests</p>
                  <Divider />
                  <div className="mt-3 flex flex-wrap items-center gap-2">
                    {Array.from({ length: 3 }, (_, index) => (
                      <Skeleton
                        key={index}
                        className="flex rounded-full w-24 h-8"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative pb-10 font-nunito mx-3 mt-3 border border-grey/30 rounded-2xl overflow-hidden">
          <div className="z-10 relative pl-6 py-6 pb-[4.5rem]">
            <div className="vertical-center gap-3">
              <LongArrow
                className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
                onClick={() => navigate(-1)}
              />
              <h3 className="text-xl">Profile</h3>
            </div>
          </div>
          <img
            src={assets?.coverbg}
            loading="lazy"
            alt="cover-image"
            className="w-full absolute top-0 left-0 z-0 h-48 object-cover rounded-t-xl"
          />
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-baseline justify-center sm:justify-between relative z-10 mb-5">
              <div className="vertical-center justify-center border-4 border-white rounded-full z-10">
                <Avatar
                  src={sPartnerDetails?.profile_image}
                  className="!size-32"
                  color="secondary"
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div className="flex flex-col items-center sm:items-start">
                <h3 className="font-manrope font-bold text-2xl flex items-center gap-2">
                  {/* Display Full Name with Proper Capitalization */}
                  {sPartnerDetails?.full_name
                    ?.split(" ")
                    ?.map((word) => capitalize(word))
                    .join(" ")}

                  {/* Show Current Status */}
                </h3>
                {sPartnerDetails?.mailing_address ? (
                  <div className="vertical-center gap-2 text-grey">
                    <LocationIcon width={16} height={16} />
                    <p className="font-normal text-base leading-7 text-grey">
                      {sPartnerDetails?.mailing_address?.city?.name +
                        ", " +
                        sPartnerDetails?.mailing_address?.state?.name +
                        ", " +
                        sPartnerDetails?.mailing_address?.zipcode}
                    </p>
                  </div>
                ) : null}
                {sPartnerDetails?.mailing_address?.physical_address ? (
                  <div className="vertical-center gap-2 text-grey">
                    <LocationIcon width={16} height={16} />
                    <p className="font-normal text-base leading-7 text-grey">
                      {sPartnerDetails?.mailing_address?.physical_address}
                    </p>
                  </div>
                ) : null}
              </div>
              {sPartnerDetails?.profession ? (
                <div className="rounded-full px-4 bg-bggrey/80 hover:bg-primary/20 vertical-center transition">
                  <BagIcon />
                  <span className="px-2 font-medium text-base leading-7">
                    {sPartnerDetails?.profession}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex justify-end mt-4">
              <div
                onClick={() => {
                  if (
                    (sPartnerDetails?.relationship?.status === "MATCH" &&
                      sPartnerDetails?.relationship?.request_status ===
                        "ACCEPTED") ||
                    (sPartnerDetails?.status === "MATCH" &&
                      sPartnerDetails?.request_status === "ACCEPTED" &&
                      !sPartnerDetails?.type)
                  ) {
                    navigate("/chat", {
                      state: {
                        user_id: id,
                        completeItem: {
                          avatar: sPartnerDetails?.profile_image || "",
                          channel_id: null,
                          chatType: "individual",
                          id: id,
                          name:
                            capitalize(sPartnerDetails?.first_name) +
                              " " +
                              capitalize(sPartnerDetails?.last_name) || "",
                          online: false,
                          selected: false,
                        },
                      },
                    });
                  } else if (
                    (!sPartnerDetails?.status &&
                      !sPartnerDetails?.request_status &&
                      !sPartnerDetails?.type) ||
                    (sPartnerDetails?.status === "MATCH" &&
                      sPartnerDetails?.request_status === "REJECTED") ||
                    (sPartnerDetails?.status === "PRIORITIZE" &&
                      !sPartnerDetails?.request_status &&
                      !sPartnerDetails?.type)
                  ) {
                    handleStatusChange("match", id);
                  } else if (
                    sPartnerDetails?.status === "MATCH" &&
                    sPartnerDetails?.request_status === "PENDING" &&
                    sPartnerDetails?.type === "pending"
                  ) {
                    // Do nothing
                  } else {
                    setOpenPartnerModal(true);
                  }
                }}
                // className="text-sm font-semibold text-primary bg-primary/10 px-4 py-2 rounded-lg cursor-pointer hover:bg-primary/20"
                className={`text-sm font-semibold text-primary bg-primary/10 px-4 py-2 rounded-lg 
                  ${
                    sPartnerDetails?.status === "MATCH" &&
                    sPartnerDetails?.request_status === "PENDING" &&
                    sPartnerDetails?.type === "pending"
                      ? "cursor-not-allowed select-none"
                      : partnerStatusLoading
                      ? "flex justify-center w-[83px] h-[35px]"
                      : "hover:bg-primary/20 cursor-pointer "
                  }`}
              >
                {(sPartnerDetails?.relationship?.status === "MATCH" &&
                  sPartnerDetails?.relationship?.request_status ===
                    "ACCEPTED" &&
                  sPartnerDetails?.relationship?.request_status !==
                    "PENDING") ||
                (sPartnerDetails?.status === "MATCH" &&
                  sPartnerDetails?.request_status === "ACCEPTED" &&
                  !sPartnerDetails?.type) ? (
                  "Chat"
                ) : partnerStatusLoading ? (
                  <Spinner />
                ) : sPartnerDetails?.status === "MATCH" &&
                  sPartnerDetails?.request_status === "PENDING" &&
                  sPartnerDetails?.type === "pending" ? (
                  "pending"
                ) : (
                  "Connect"
                )}
              </div>
            </div>
            <div className="smd:grid grid-cols-12 gap-1 mb-6">
              <div className="flex flex-col gap-4 w-full col-start-1 col-span-6 max-smd:mt-10">
                <div className="vertical-center gap-2 mt-4">
                  <MailIcon width={18} height={18} />
                  <p className="text-[15px] text-darkgrey leading-4">
                    {sPartnerDetails?.email}
                  </p>
                </div>

                <div className="vertical-center gap-2">
                  <PhoneIcon width={19} height={19} />
                  <p className="text-sm text-darkgrey leading-4">
                    {sPartnerDetails?.phone_no}
                  </p>
                </div>

                {sPartnerDetails?.operated_address ? (
                  <div className="vertical-center gap-2 text-darkgrey">
                    <LocationIcon width={16} height={16} />
                    <p className="font-normal text-sm leading-4 text-darkgrey">
                      <span className="font-bold">Operate from: </span>
                      {sPartnerDetails?.operated_address?.city?.name +
                        ", " +
                        sPartnerDetails?.operated_address?.state?.name +
                        ", " +
                        sPartnerDetails?.operated_address?.zipcode}
                    </p>
                  </div>
                ) : null}

                {sPartnerDetails?.birthday ? (
                  <div className="vertical-center gap-2">
                    <BirthdayIcon width={19} height={19} />
                    <p className="text-sm text-darkgrey leading-4">
                      {formatDate(sPartnerDetails?.birthday)}
                    </p>
                  </div>
                ) : null}

                {/* Description Section */}
                {sPartnerDetails?.user_description && (
                  <div className="w-[290px]">
                    <Divider />
                    <p className="text-sm leading-5 text-darkgrey mt-3 ">
                      {sPartnerDetails?.user_description}
                    </p>
                  </div>
                )}
              </div>

              <div className="w-full max-smd:mt-10 col-span-7 smd:col-span-5 md:col-span-4 lg:col-span-3 col-start-7 smd:col-start-8 md:col-start-9 lg:col-start-10">
                <div>
                  <p className="text-md font-semibold">Role</p>
                  <Divider />
                  <div className="mt-3 flex flex-wrap items-center gap-2">
                    {sPartnerDetails?.user_roles &&
                      sPartnerDetails?.user_roles.map((role, index) => (
                        <p
                          key={index}
                          className="text-sm bg-gold/30 rounded-full w-fit px-3 py-1"
                        >
                          {role}
                        </p>
                      ))}
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-md font-semibold">Interests</p>
                  <Divider />
                  <div className="mt-3 flex flex-wrap items-center gap-2">
                    {sPartnerDetails?.user_interests &&
                      sPartnerDetails?.user_interests?.map(
                        (interest, index) => (
                          <p
                            key={index}
                            className="text-sm bg-gold/30 rounded-full w-fit px-3 py-1"
                          >
                            {interest}
                          </p>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            {sPartnerDetails?.experiences?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Experiences</p>
                <Experiences
                  loading={sPartnerDetailsLoading}
                  profileData={sPartnerDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />
            {sPartnerDetails?.portfolio_images?.length > 0 ? (
              <div className="my-6 ">
                <div className={`relative flex justify-between items-center `}>
                  <p className="text-lg font-semibold">Portfolio</p>
                </div>

                <Carousel
                  partnerDetailsimages={sPartnerDetails?.portfolio_images}
                />
              </div>
            ) : (
              ""
            )}
            <Divider />
            {sPartnerDetails?.project_experiences?.length > 0 ? (
              <div className="my-6 cursor-pointer">
                <p className="text-lg font-semibold">Projects</p>
                <Projects
                  loading={sPartnerDetailsLoading}
                  profileData={sPartnerDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {sPartnerDetails?.education?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Education</p>
                <Education
                  loading={sPartnerDetailsLoading}
                  profileData={sPartnerDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {sPartnerDetails?.certifications?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Certifications</p>
                <Certifications
                  loading={sPartnerDetailsLoading}
                  profileData={sPartnerDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {sPartnerDetails?.licenses?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Licences</p>
                <Licences
                  loading={sPartnerDetailsLoading}
                  profileData={sPartnerDetails}
                  handViewOpenModal={handViewOpenModal}
                />
              </div>
            ) : null}

            <Divider />

            {sPartnerDetails?.recommendations?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Recommendations</p>
                <Recommendations
                  loading={sPartnerDetailsLoading}
                  profileData={sPartnerDetails}
                />
              </div>
            ) : null}

            <Divider />

            {sPartnerDetails?.skills?.length > 0 ? (
              <div className="my-6">
                <p className="text-lg font-semibold">Skills</p>
                <Skills
                  loading={sPartnerDetailsLoading}
                  skills={sPartnerDetails?.skills}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}

      {/* Modal  */}
      <CustomModal
        title={
          sPartnerDetails?.relationship?.status === "MATCH" &&
          sPartnerDetails?.relationship?.request_status === "ACCEPTED"
            ? "MATCH"
            : "Connect"
        }
        open={openPartnerModal}
        close={handleCloseModal}
        showSecondaryButton={false}
        showPrimaryButton={false}
      >
        <div className="font-nunito pb-3">
          <div className="flex justify-center items-center gap-5">
            {sPartnerDetails?.relationship?.request_status === "PENDING" ? (
              <>
                <PartnersProfileButton
                  icon={<TrueIcon color={"#1E009F"} />}
                  value={"ACCEPT"}
                  selectedValue={status}
                  onChange={(event) =>
                    handleStatusChange(event.target.value, id)
                  }
                  label="ACCEPT"
                />

                <PartnersProfileButton
                  icon={<CrossIcon color={"red"} height={24} width={24} />}
                  value={"REJECT"}
                  selectedValue={status}
                  onChange={(event) =>
                    handleStatusChange(event.target.value, id)
                  }
                  label="REJECT"
                />
              </>
            ) : sPartnerDetails?.relationship === null ||
              sPartnerDetails?.relationship?.status === "PRIORITIZE" ? (
              <>
                {/* <PartnersProfileButton
                  icon={<CrossIcon color={"red"} height={24} width={24} />}
                  value={"deprioritize"}
                  selectedValue={status}
                  onChange={(event) =>
                    handleStatusChange(event.target.value, id)
                  }
                  label="Skip"
                /> */}
                <PartnersProfileButton
                  icon={<TrueIcon color={"#1E009F"} />}
                  value={"match"}
                  selectedValue={status}
                  onChange={(event) =>
                    handleStatusChange(event.target.value, id)
                  }
                  label="Match"
                />
              </>
            ) : sPartnerDetails?.relationship?.status === "MATCH" &&
              sPartnerDetails?.relationship?.request_status === "ACCEPTED" ? (
              <PartnersProfileButton
                icon={<ChatIcon color={"#1E009F"} />}
                // value={"match"}
                selectedValue={status}
                onChange={(event) =>
                  navigate(`/chat`, {
                    state: {
                      user_id: id,
                      completeItem: {
                        avatar: sPartnerDetails?.profile_image || "",
                        channel_id: null,
                        chatType: "individual",
                        id: id,
                        name:
                          capitalize(sPartnerDetails?.first_name) +
                            " " +
                            capitalize(sPartnerDetails?.last_name) || "",
                        online: false,
                        selected: false,
                      },
                    },
                  })
                }
                label="Chat"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </CustomModal>

      {Object.keys(modals).map((key) => (
        <DetailModal
          key={key}
          title={key.charAt(0).toUpperCase() + key.slice(1)}
          data={modals[key].data}
          open={modals[key].open}
          onClose={handleNewCloseModal}
        />
      ))}
    </>
  );
};

export default PartnersDetails;
