/* eslint-disable react-hooks/exhaustive-deps */
// Library Imports
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Local Imports
import { showToast, Spinner, StatsCard } from "../../../components/shared";
import { PartnersIcon, ProjectsIcon, UsersIcon } from "../../../assets/icons";
import BarChart from "../../../components/shared/Charts/BarChart";
import axiosInstance from '../../../redux/network/https';
import { endPoints } from '../../../redux/network/constant';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dashBoardStats, setDashBoardStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    inActiveUsers: 0,
    totalProjects: 4,
  });
  const [graphData, setGraphData] = useState([]);
  const [screenLoading, SetScreenLoading] = useState([])

  const processData = (data) => {
    return data?.reduce((acc, { created_at, is_active }) => {
      const date = new Date(created_at);
      const year = date.getFullYear();
      const month = date.getMonth();

      acc[year] = acc[year] || { active: Array(12)?.fill(0), inactive: Array(12)?.fill(0) };

      acc[year][is_active ? "active" : "inactive"][month]++;

      return acc;
    }, {});
  };

  useEffect(() => {
    const getUserListing = async () => {
      SetScreenLoading(true)
      axiosInstance.get(endPoints.Users).then((response) => {
        const userListing = response?.data?.data
        setDashBoardStats({
          ...dashBoardStats,
          totalUsers: userListing?.length || 0,
          activeUsers: userListing?.filter(
            (user) => user?.is_active === true
          )?.length || 0,
          inActiveUsers: userListing?.filter(
            (user) => user?.is_active === false
          )?.length || 0,
        });
        const data = processData(userListing);
        setGraphData(data);
        SetScreenLoading(false)
      }).catch((error) => {
        showToast("Something went wrong", 'error', { position: "top-center" });
        SetScreenLoading(false)
      })

    };
    getUserListing();
  }, [dispatch]);

  return (
    <React.Fragment>
      {screenLoading ?
        <div className="flex items-center justify-center h-screen">
          <Spinner className={"text-secondary size-10"} />
        </div>
        :
        <div className="p-4 md:p-6 2xl:p-10">
          {/* Stats Card */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <StatsCard
              title="All Users"
              total={dashBoardStats?.totalUsers}
              rate={"100%"}
              levelUp
            >
              <UsersIcon className="h-16 w-20 !text-primary" />
            </StatsCard>
            <StatsCard
              title="Active Users"
              total={dashBoardStats?.activeUsers}
              rate={
                dashBoardStats?.activeUsers && dashBoardStats?.totalUsers
                  ? (
                    (dashBoardStats.activeUsers / dashBoardStats.totalUsers) *
                    100
                  ).toFixed(2) + "%"
                  : "50%"
              }
              levelUp
            >
              <PartnersIcon className="h-16 w-20 !text-primary" />
            </StatsCard>
            <StatsCard
              title="InActive Users"
              total={dashBoardStats?.inActiveUsers}
              rate={
                dashBoardStats?.inActiveUsers && dashBoardStats?.totalUsers
                  ? (
                    (dashBoardStats.inActiveUsers / dashBoardStats.totalUsers) *
                    100
                  ).toFixed(2) + "%"
                  : "50%"
              }
              levelDown
            >
              <PartnersIcon className="h-16 w-20 !text-secondary" />
            </StatsCard>
            <StatsCard
              title="Total Projects"
              total={dashBoardStats?.totalProjects}
              rate={"10%"}
              levelDown
            >
              <ProjectsIcon className="h-16 w-20 !text-secondary" />
            </StatsCard>
          </div>
          {/* Charts */}
          <div className="my-4 rounded-md border bg-white pt-4 pb-2 px-2 shadow h-full">
            <BarChart
              yearlyData={graphData}
              availableYears={Object.keys(graphData)}
            />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Dashboard;
