// Library Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Local Imports
import axios from "../../network/https";
import { endPoints } from "../../network/constant";

// Initial state for users
const initialState = {
  usersListing: [],
  usersListingLoading: false,
  usersListingError: null,

  // Delete User
  userDetail: null,
  userDetailLoading: false,
  userDetailError: null,

  // Update User Status
  updateUserStatusLoading: false,
  updateUserStatusError: null,
};

// Users Listing API Function
export const usersListingFunction = createAsyncThunk(
  "admin/usersListing",
  async (searchQuery, { rejectWithValue }) => {
    let endPoint = endPoints.Users;
    if (searchQuery) {
      endPoint += `?search=${searchQuery}`;
    }

    try {
      const { data } = await axios.get(endPoint);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Get User API Function
export const getUserDetailFunction = createAsyncThunk(
  "admin/getUser",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.Users + values.id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Delete User API Function
export const deleteUserFunction = createAsyncThunk(
  "admin/deleteUser",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(endPoints.Users + values.id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Update User Status API Function
export const updateUserStatusFunction = createAsyncThunk(
  "admin/updateUserStatus",
  async ({ userId, is_active }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(`${endPoints.Users}${userId}/`, {
        is_active,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "Failed to update user status"
      );
    }
  }
);

// Reducers
export const usersListingSlice = createSlice({
  name: "admin/usersListing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(usersListingFunction.pending, (state) => {
        state.usersListingLoading = true;
        state.error = null;
        state.usersListing = [];
      })
      .addCase(usersListingFunction.fulfilled, (state, action) => {
        state.usersListingLoading = false;
        state.usersListing = action.payload;
        state.error = null;
      })
      .addCase(usersListingFunction.rejected, (state, action) => {
        state.usersListingLoading = false;
        state.error = action.payload;
        state.usersListing = [];
      })
      .addCase(getUserDetailFunction.pending, (state) => {
        state.userDetailLoading = true;
        state.userDetailError = null;
        state.userDetail = null;
      })
      .addCase(getUserDetailFunction.fulfilled, (state, action) => {
        state.userDetailLoading = false;
        state.userDetail = action.payload;
        state.userDetailError = null;
      })
      .addCase(getUserDetailFunction.rejected, (state, action) => {
        state.userDetailLoading = false;
        state.userDetailError = action.payload;
        state.userDetail = null;
      })
      .addCase(deleteUserFunction.pending, (state) => {
        state.userDetailLoading = true;
        state.userDetailError = null;
      })
      .addCase(deleteUserFunction.fulfilled, (state, action) => {
        state.userDetailLoading = false;
        state.userDetailError = null;
      })
      .addCase(deleteUserFunction.rejected, (state, action) => {
        state.userDetailLoading = false;
        state.userDetail = null;
      })

      .addCase(updateUserStatusFunction.pending, (state) => {
        state.updateUserStatusLoading = true;
        state.updateUserStatusError = null;
      })
      .addCase(updateUserStatusFunction.fulfilled, (state) => {
        state.updateUserStatusLoading = false;
        state.updateUserStatusError = null;
      })
      .addCase(updateUserStatusFunction.rejected, (state, action) => {
        state.updateUserStatusLoading = false;
        state.updateUserStatusError = action.payload;
      });
  },
});

export default usersListingSlice.reducer;
